<template>
  <div>
    <div class="shoots-list-ele-top-text">
      <div class="first-text">{{ item.orderGroupName }}</div>
      <div class="second-text">小计</div>
      <div class="third-text">{{ item.groupSum.toFixed(1) }}</div>
    </div>
    <AllShootsChildBox
      v-for="(arr, index) in item.scoreData"
      :arr="arr"
      :key="index"
    ></AllShootsChildBox>
  </div>
</template>

<script>
import AllShootsChildBox from "@/components/list/AllShootsChildBox.vue";

export default {
  name: "AllShootsParentBox",
  props: {
    item: {
      default: {},
      type: Object,
    },
  },
  components: {
    AllShootsChildBox,
  },
  data() {
    return {
    };
  },
  methods: {
  },
};
</script>

<style>
</style>