<template>
  <div class="group-and-lane-ele">
    <div class="group-and-lane-ele-text">队伍：</div>
    <div class="group-and-lane-ele-num">
      {{ team < 10 ? "0" + team : team }}
    </div>
    <div class="group-and-lane-ele-text">序号：</div>
    <div class="group-and-lane-ele-num">
      {{ number < 10 ? "0" + number : number }}
    </div>
  </div>
</template>

<script>
export default {
  name: "GroupAndTargetBox",
  props: {
    team: {
      default: 0,
      type: Number,
    },
    number: {
      default: 0,
      type: Number,
    },
  },
};
</script>

<style></style>
