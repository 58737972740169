<!-- 已经不使用的文件放在recycle文件夹中，但有参考价值 -->

<template>
  <!--总体布局-->
  <div class="all" v-cloak>
    <!--选手决赛完成遮罩区域-->
    <FinishFinalsMaskBox
      v-if="isOverMask"
      :player_info="vue_playerInfo"
      :rank_num="rank_num"
      :rank_color="rank_color"
      :total_score="total_score"
    ></FinishFinalsMaskBox>
    <!--背景区域-->
    <div class="background">
      <div class="top1">
        <div class="left">
          <!--靶区域-->
          <TargetBox
            :string="target_type_string"
            :name="vue_matchInfo.competitionName"
            :match_type="match_type"
            :shoot_point_array="shoot_point_array"
            :isShooting="isShooting"
            ref="targetBox"
          ></TargetBox>
          <div class="right">
            <!--赛事阶段区域-->
            <MatchStageBox :match_stage="match_stage"></MatchStageBox>
            <!--所有射击成绩区域-->
            <AllShootsParentBox
              :isOddShootingFinals="isOddShootingFinals"
              :isRecoveringData="isRecoveringData"
              ref="listBox"
            ></AllShootsParentBox>
            <!--射击发数区域-->
            <CurrentOrderBox :current_order="current_order"></CurrentOrderBox>
          </div>
        </div>
        <div class="right">
          <!--排名区域-->
          <RankBox ref="rankBox" v-on:rank_and_color="setRankAndColor"></RankBox>
          <div class="bottom1">
            <!--当前环数区域-->
            <CurrentRingBox :newest_score="newest_score"></CurrentRingBox>
            <!--总计成绩区域-->
            <TotalScoreBox :total_score="total_score"></TotalScoreBox>
          </div>
        </div>
      </div>
      <div class="bottom">
        <!--分组靶道信息区域-->
        <GroupAndTargetBox
          :group_info="vue_groupInfo"
          :target_line="target_line"
        ></GroupAndTargetBox>
        <!--选手信息区域-->
        <div class="athlete_info-box">
          <!--选手姓名区域-->
          <PlayerNameBox :player_info="vue_playerInfo"></PlayerNameBox>
          <!--选手团队区域-->
          <PlayerTeamBox :player_info="vue_playerInfo"></PlayerTeamBox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import MatchOverMaskBox from "@/components/MatchOverMaskBox.vue";
import FinishFinalsMaskBox from "@/components/mask/FinishFinalsMaskBox.vue";
import TargetBox from "@/components/target/TargetBox.vue";
import MatchStageBox from "@/components/stage/MatchStageBox.vue";
import AllShootsParentBox from "@/components/list/AllShootsParentBox.vue";
import CurrentOrderBox from "@/components/order/CurrentOrderBox.vue";
import RankBox from "@/components/rank/RankBoxOld.vue";
import CurrentRingBox from "@/components/ring/CurrentRingBox.vue";
import TotalScoreBox from "@/components/total/TotalScoreBox.vue";
import GroupAndTargetBox from "@/components/group/GroupAndTargetBox.vue";
import PlayerNameBox from "@/components/player/PlayerNameBox.vue";
import PlayerTeamBox from "@/components/player/PlayerTeamBox.vue";

export default {
  name: "AthleteScreen",
  components: {
    // MatchOverMaskBox,
    FinishFinalsMaskBox,
    TargetBox,
    MatchStageBox,
    AllShootsParentBox,
    CurrentOrderBox,
    RankBox,
    CurrentRingBox,
    TotalScoreBox,
    GroupAndTargetBox,
    PlayerNameBox,
    PlayerTeamBox,
  },
  created() {
    /**
     * 提取场馆号
     */
    // this.venue_num = parseInt(this.$route.query.venueId);
    /**
     * 提取靶道号
     */
    // this.target_line = parseInt(this.$route.query.targetId);
    /**
     * 获取页面跳转参数扫码数据
     */
    this.qrParams = this.$router.query.wsData;
  },
  mounted() {
    /**
     * 页面创建后建立连接
     */
    // this.socketServer();
  },
  data() {
    return {
      qrParams: {},//扫码跳转数据
      // globalUrl: "http://localhost:9062", //全局请求地址
      lockReconnect: false, //避免重复连接
      isShowMask: false, //是否显示遮罩，默认无赛事且显示遮罩
      isOverMask: false, //是否显示遮罩，默认未完成决赛且不显示遮罩
      maskText: "", //遮罩显示文本
      /**
       * 靶区域
       */
      vue_matchInfo: {}, //比赛信息
      match_type: "", //靶类型
      target_type_string: "", //靶区域字符串
      shoot_point_array: [], //所有射击点坐标集合数组
      match_stage: "", //比赛阶段
      /**
       * 比赛进行环节
       */
      isRecoveringData: false, //是否正在恢复数据
      isNeedOddFinals: false, //该靶道是否需要准备单发射击，默认为false
      isOddShootingFinals: false, //是否正在进行单发射击决赛，默认为false
      isFinishMatchFinals: false, //是否完成比赛决赛或决赛被淘汰，默认为false
      isShooting: false, //当前靶道是否正在进行射击
      isTesting: false, //是否正在进行试射
      current_order: 0, //当前发序
      shoot_count: 0,//射击次数
      shoot_exit_rank_array: [], //所有存在射击的靶道排名集合数组
      rank_num: 0,
      rank_color: "",
      newest_score: 0, //最新成绩
      total_score: 0, //总成绩
      avg_score: 0,//平均成绩
      standard_deviation: 0,//标准差
      vue_groupInfo: {
        currentGroup: 0,//资格赛分组
      },
      target_line: 0,//靶道
      venue_num: 0,//场馆
      vue_playerInfo: {
        athleteId: "",
        athleteName: "",
        athleteNation: "",
        athleteTeam: "",
      }, //选手信息
    };
  },
  methods: {
    /**
     * 接收回传的排名及颜色对象并赋值
     */
    setRankAndColor: function (result) {
      this.rank_num = result.rank_num;
      this.rank_color = result.rank_color;
    },
    /**
     * 清空试射数据操作
     */
    clearTestingData: function () {
      // 清空射击点坐标集合数组
      this.shoot_point_array = [];
      // 清空射击点
      this.$refs.targetBox.cleanPoint();
      //清空列表中所有射击数据
      this.$refs.listBox.clearGroupListData();
      //重置当前发序
      this.current_order = 0;
      //重置当前环数区域
      this.newest_score = 0;
      //重置总成绩区域
      this.total_score = 0;
    },
    /**
     * 服务器断线重连清空数据操作
     */
    clearDisconnectData: function () {
      // 清空射击点坐标集合数组
      this.shoot_point_array = [];
      // 清空射击点
      this.$refs.targetBox.cleanPoint();
      //清空列表中所有射击数据
      this.$refs.listBox.clearGroupListData();
      //重置当前环数区域
      this.newest_score = 0;
      //重置总成绩区域
      this.total_score = 0;
    },
    /**
     * 当接收到新比赛信息或比赛结束信号清空比赛数据和重置比赛状态
     */
    clearMatchData: function () {
      //清空列表中所有射击数据
      this.$refs.listBox.clearGroupListData();
      //重置靶图缩放大小
      this.rifle_target_scale_ratio = 45.5 / 15.5;
      this.pistol_target_scale_ratio = 155.5 / 59.5;
      // 当接受到新的比赛信息时，排名区域重置
      this.$refs.rankBox.resetAni();
      // 清空射击点坐标集合数组
      this.shoot_point_array = [];
      // 清空射击点
      this.$refs.targetBox.cleanPoint();
      // 当接收到新的比赛信息时，清空排名数组
      this.shoot_exit_rank_array = [];
      //重置当前发序
      this.current_order = 0;
      //重置当前环数区域
      this.newest_score = 0;
      //重置总成绩区域
      this.total_score = 0;
      // 重置目前赛事状态
      this.isNeedOddFinals = false;
      this.isOddShootingFinals = false;
      this.isFinishMatchFinals = false;
      // 重置决赛完赛遮罩
      this.isOverMask = false;
    },
    /**
     * 清空资格赛阶段上一组选手射击数据
     */
    clearLastGroupPlayerData: function () {
      //清空列表中所有射击数据
      this.$refs.listBox.clearGroupListData();
      //重置靶图缩放大小
      this.rifle_target_scale_ratio = 45.5 / 15.5;
      this.pistol_target_scale_ratio = 155.5 / 59.5;
      // 当接受到新的比赛信息时，排名区域重置
      this.$refs.rankBox.resetAni();
      // 清空射击点坐标集合数组
      this.shoot_point_array = [];
      // 清空射击点
      this.$refs.targetBox.cleanPoint();
      // 当接收到新的比赛信息时，清空排名数组
      this.shoot_exit_rank_array = [];
      //重置当前发序
      this.current_order = 0;
      //重置当前环数区域
      this.newest_score = 0;
      //重置总成绩区域
      this.total_score = 0;
    },
    /**
     * 处理扫码页面跳转参数
     */
    qr_info: function () {

    },
    /**
     * Ajax全局请求方法
     */
    requestServer: function (spliceUrl, paramData) {
      // 请求返回数据存放
      var ajaxData;
      $.ajax({
        async: false,
        url: this.globalUrl + spliceUrl,
        type: "get",
        dataType: "json",
        data: paramData,
        success: function (data) {
          ajaxData = data;
        },
      });
      return ajaxData.data;
    },
    /**
     * WebSocket连接方法
     */
    socketServer: function () {
      var that = this;
      // WebSocket获取数据
      // 云服务器地址 + 场馆编号 + 靶道编号
      // var host =
      //   "ws://localhost:3881/athleteView/" +
      //   this.venue_num +
      //   "/" +
      //   this.target_line;
      // var host = "ws://192.168.3.122:9062/ws/athlete-view/athleteView/connect";
      var ws = new WebSocket(host);
      ws.onopen = function (event) {
        console.log("Connection open ...");
      };
      var onMsg = function (event) {
        var info = JSON.parse(event.data);
        console.log(info);
        // switch (info.type) {
        //   case 0: //暂无赛事
        //     that.no_match();
        //     break;
        //   case 1: //赛事信息
        //     //获取比赛类型初始化靶以及分组靶道
        //     that.match_info(info.data);
        //     break;
        //   case 2: //切换分组
        //     //获取新分组开始更新选手信息、清空射击数据
        //     that.group_change(info.data);
        //     break;
        //   case 3: //开始试射
        //     //获取排名区域中“比赛即将开始“、”正在试射“、”比赛开始“三个字样以及排名展示的信号
        //     that.signalTest();
        //     break;
        //   case 4: //正式射击
        //     that.signalReady();
        //     break;
        //   case 5: //继续射击
        //     that.signalContinueShoot();
        //     break;
        //   case 6: //单发决赛
        //     that.signalOddFinals();
        //     break;
        //   case 7: //比赛结束
        //     that.signalMatchEnd();
        //     break;
        //   case 8: //需要单发射击
        //     that.signalNeedOddFinals();
        //     break;
        //   case 9: //射击数据
        //     //获取所有靶道成绩数据集合（判断射击点坐标数据、发序、方位、当前成绩、总成绩）
        //     that.shoot_info(info.data);
        //     break;
        //   case 10: //排名数据
        //     //实现排名动画
        //     that.rank_info(info.data);
        //     break;
        //   case 11: //缩放数据
        //     //获取Canvas放大缩小信号
        //     that.zoom_info(info.data);
        //     break;
        //   case 12: //选手淘汰
        //     that.over_info();
        //     break;
        //   case 14: //冠军信息（表示射击结束）
        //     that.shoot_end();
        //     break;
        //   case 16: //上膛信息，表示正在射击
        //     that.signalShooting();
        //     break;
        //   case 17: //瞄点曲线数据
        //     break;
        //   default:
        //     break;
        // }
      };
      ws.onmessage = onMsg;
      ws.onclose = function (event) {
        console.log("Connection closed!");
        reconnect(host);
      };
      // 断线重连方法
      function reconnect(uri) {
        console.log("Connection reconnect");
        //断线重连隐藏决赛完赛后遮罩
        that.isOverMask = false;
        //断连重连显示LOGO遮罩
        that.isShowMask = false;
        //断连重连更新遮罩文本
        that.maskText = "断线重连中...";
        if (!that.lockReconnect) {
          that.lockReconnect = true;
          setTimeout(function () {
            ws = new WebSocket(uri);
            ws.onmessage = onMsg;
            ws.onclose = function () {
              reconnect(uri);
            };
            ws.onerror = function () {
              reconnect(uri);
            };
            that.lockReconnect = false;
          }, 5000);
        }
      }
    },
    /**
     * 暂无赛事
     */
    no_match: function () {
      //隐藏完赛遮罩
      this.isOverMask = false;
      //显示LOGO遮罩
      this.isShowMask = false;
      //无赛事更新遮罩文本
      this.maskText = "暂无赛事信息";
    },
    /**
     * 处理比赛JSON信息
     */
    match_info: function (match_data) {
      var info = JSON.parse(match_data);
      console.log(info);
      // 当接收到新的比赛信息清空比赛数据
      this.clearMatchData();
      this.isShowMask = true; //存在赛事显示射击界面
      var competitionId = parseInt(info.competitionId);
      var group = info.currentGroup;
      var type;
      if (info.competitionEvent.indexOf("步枪") != -1) {
        type = "步枪";
      } else if (info.competitionEvent.indexOf("手枪") != -1) {
        type = "手枪";
      }
      this.match_type = type; //比赛类型赋值vue
      this.target_type_string = "10 米激光" + type + "（中心成绩 10.9）"; //靶类型字符串赋值vue
      this.vue_matchInfo = info; //比赛类型赋值vue
      // 绘制靶图
      this.$refs.targetBox.drawTarget();
      var stage = info.stage;
      if (stage == 1) {
        this.match_stage = "资格赛"; //比赛阶段赋值vue
      } else if (stage == 2) {
        this.match_stage = "决赛";
      }
      this.vue_groupInfo.currentGroup = group; //分组赋值vue
      // 数据恢复操作
      if (group != 0) {
        this.data_reload(competitionId, group);
      }
    },
    /**
     * 处理数据恢复逻辑
     */
    data_reload: function (competitionId, group) {
      console.log("正在恢复数据...");
      this.isRecoveringData = true;
      var list; //存放恢复射击单位数据
      var str1 = "/reload/testTime";
      var str2 = "/reload/athlete";
      var str3 = "/reload/athleteName";
      var pData1 = { competitionId: competitionId };
      var pData2 = {
        competitionId: competitionId,
        group: group,
        target: this.target_line,
      };
      var pData3 = {
        venueId: this.venue_num,
        target: this.target_line,
      };
      //系统崩溃重连后数据恢复需要将旧数据清空
      this.clearDisconnectData();
      if (request(str1, pData1) > 0) {
        this.isTesting = true; // 试射状态
        this.$refs.rankBox.testingText(); //排名区域正在试射
      } else {
        this.$refs.rankBox.startText(); //排名区域正式射击
        // 恢复该靶道射击数据
        var recoverShootData = request(str2, pData2);
        for (var i = 0; i < recoverShootData.length; i++) {
          list = {
            orderNo:
              recoverShootData[i].order > 10000
                ? "*"
                : recoverShootData[i].order, //发序
            shootPoint: {
              x: recoverShootData[i].aim.x,
              y: recoverShootData[i].aim.y,
            }, //射击点坐标
            shootDirection: recoverShootData[i].direction, //方位
            shootScore: recoverShootData[i].score, //当前环数成绩
            shootTotal: recoverShootData[i].totalScore, //总成绩
          };
          // 资格赛、决赛发序分组与射击点显示策略
          if (this.match_stage == "资格赛") {
            if (recoverShootData[i].order % 10 == 1) {
              this.shoot_point_array = [];
            }
            this.shoot_point_array.push(recoverShootData[i].aim);
            this.$refs.listBox.orderAddQualifying(list);
          } else if (this.match_stage == "决赛") {
            if (recoverShootData[i].order <= 11) {
              if (recoverShootData[i].order % 5 == 1) {
                this.shoot_point_array = [];
              }
            } else {
              if (recoverShootData[i].order % 2 == 1) {
                this.shoot_point_array = [];
              }
            }
            this.shoot_point_array.push(recoverShootData[i].aim);
            this.$refs.listBox.orderAddFinals(list);
          }
          if (i == recoverShootData.length - 1) {
            this.current_order = recoverShootData[i].order;
            this.newest_score = recoverShootData[i].score;
            this.total_score = recoverShootData[i].totalScore;
          }
        }
        // 数据恢复重绘所有射击点
        this.$refs.targetBox.repaint_all_points();
        // 恢复该靶道选手信息
        this.vue_playerInfo.athleteName = request(str3, pData3).athleteName;
        this.vue_playerInfo.athleteTeam = request(str3, pData3).team;
      }
      console.log("数据恢复完成...");
    },
    /**
     * 处理分组切换选手JSON信息
     */
    group_change: function (player_data) {
      if (player_data.length > 0) {
        var info = JSON.parse(player_data);
        console.log(info);
        // 分组加1
        this.vue_groupInfo.currentGroup++;
        // 选手信息赋值vue
        this.vue_playerInfo.athleteName = info.athleteName;
        this.vue_playerInfo.athleteTeam = info.team;
        // 当接受到分组切换信号就更新投显展示字样、数据及状态
        this.clearLastGroupPlayerData();
        // 当接受到分组切换信号更新排名区域为比赛即将开始
        this.$refs.rankBox.readyText();
      }
    },
    /**
     * 处理所有靶道射击数据JSON信息
     */
    shoot_info: function (shoot_data) {
      var info = JSON.parse(shoot_data);
      console.log(info);
      // 当接受到射击数据证明不正在数据恢复
      this.isRecoveringData = false;
      // 当接收到射击数据证明不正在最新射击
      this.isShooting = false;
      // 更新当前成绩和总成绩的展示
      this.newest_score = info.score;
      if (
        !(
          this.isNeedOddFinals ||
          this.isOddShootingFinals ||
          this.isFinishMatchFinals
        )
      ) {
        //当前发序赋值vue
        this.current_order = info.aim.order;
        //如果不是进行单发射击或者选手被淘汰则正常更新总成绩
        this.total_score = info.totalScore;
      }
      var list = {
        orderNo: this.isOddShootingFinals == false ? info.aim.order : "*",
        shootPoint: { x: info.aim.x, y: info.aim.y }, //射击点坐标
        shootDirection: info.direction, //方位
        shootScore: info.score, //当前环数成绩
        shootTotal: info.totalScore, //总成绩
      };
      // 资格赛、决赛发序分组与射击点显示策略
      if (this.match_stage == "资格赛") {
        if (info.aim.order % 10 == 1) {
          this.shoot_point_array = [];
        }
        this.shoot_point_array.push(info.aim);
        if (!this.isTesting) {
          //非试射阶段展示列表
          this.$refs.listBox.orderAddQualifying(list);
        }
        this.$refs.targetBox.repaint_all_points();
      } else if (this.match_stage == "决赛") {
        if (!this.isOddShootingFinals) {
          //如果不是单发射击
          if (info.aim.order <= 11) {
            if (info.aim.order % 5 == 1) {
              this.shoot_point_array = [];
            }
          } else {
            if (info.aim.order % 2 == 1) {
              this.shoot_point_array = [];
            }
          }
          this.shoot_point_array.push(info.aim);
          if (!this.isTesting) {
            this.$refs.listBox.orderAddFinals(list);
          }
          this.$refs.targetBox.repaint_all_points();
        } else {
          //正在单发射击
          if (this.isNeedOddFinals) {
            //如果该靶道是准备进行单发射击的靶道
            this.shoot_point_array = [];
            this.shoot_point_array.push(info.aim);
            this.$refs.listBox.orderAddFinals(list);
            this.$refs.targetBox.repaint_all_points();
          } else {
            //如果该靶道不是准备进行单发射击的靶道，则数据不变
          }
        }
      }
    },
    /**
     * 处理排名集合JSON信息
     */
    rank_info: function (rank_data) {
      // 如果当前靶道是需要单发决赛或已完成比赛则不会处理排名信息
      if (this.isNeedOddFinals == true || this.isFinishMatchFinals == true) {
        return;
      }
      var info = JSON.parse(rank_data);
      var oldRankArray = this.shoot_exit_rank_array;
      var newRankArray = info;
      var targetLine = this.target_line;
      // 当接收到射击数据开启排名显示
      this.$refs.rankBox.display_rank();
      // 调用排名显示动画处理方法
      this.$refs.rankBox.rankBoxShowLogic(
        oldRankArray,
        newRankArray,
        targetLine
      );
      // 处理完所有动画将新排名数组赋值vue
      this.shoot_exit_rank_array = newRankArray;
    },
    /**
     * 处理Canvas缩放JSON信息
     */
    zoom_info: function (zoom_data) {
      var info = JSON.parse(zoom_data);
      var size = info.size;
      // 限制靶图最大放大显示到7环
      if (size !== 0 && size <= 7) {
        this.$refs.targetBox.setCanvasSize(size);
      }
    },
    /**
     * 处理试射信号
     */
    signalTest: function () {
      // 当接受到试射信号改变状态
      this.isTesting = true;
      // 当接受到试射信号就更新展示字样
      this.$refs.rankBox.testingText();
    },
    /**
     * 处理开始信号
     */
    signalReady: function () {
      // 结束试射状态
      this.isTesting = false;
      // 当接受到开始信号就清空试射数据
      this.clearTestingData();
      // 当接受到开始信号就更新展示字样
      this.$refs.rankBox.startText();
      // 当接受到开始信号就将发序清零
      this.current_order = 0;
    },
    /**
     * 处理需要进行单发射击
     */
    signalNeedOddFinals: function () {
      this.isNeedOddFinals = true; //将当前靶道状态改为需要准备进行单发射击状态
      this.$refs.rankBox.needOddFinalsText(); //修改排名区域需要进行单发射击显示字样
    },
    /**
     * 处理单发射击信号
     */
    signalOddFinals: function () {
      this.isOddShootingFinals = true; //将当前靶道状态改为正在进行单发射击状态
      if (this.isNeedOddFinals && this.isOddShootingFinals) {
        this.$refs.rankBox.oddShootFinalsText(); //修改排名区域正在进行单发射击显示字样
      }
    },
    /**
     * 处理继续射击信号
     */
    signalContinueShoot: function () {
      //结束单发射击状态
      this.isNeedOddFinals = false;
      this.isOddShootingFinals = false;
      //处理继续射击恢复排名区域状态
      this.$refs.rankBox.setRankAndColor(this.rank_num);
      this.$refs.rankBox.continueMatch();
    },
    /**
     * 处理比赛结束信号
     */
    signalMatchEnd: function () {
      // 清空比赛数据
      this.clearMatchData();
      // 显示遮罩
      this.no_match();
    },
    /**
     * 处理选手淘汰信号
     */
    over_info: function () {
      var that = this;
      //获取最后一发非单发射击的发序求出最终排名并赋值Vue
      var last_rank = 8 - (this.current_order - 12) / 2;
      if (this.isOddShootingFinals == true && this.isNeedOddFinals) {
        //判断接收淘汰信号是否是在单发射击后接收
        //结束单发射击状态
        this.isNeedOddFinals = false;
        this.isOddShootingFinals = false;
        //出现选手淘汰处理排名区域状态
        this.$refs.rankBox.setRankAndColor(last_rank);
        this.$refs.rankBox.matchOver();
        this.$refs.rankBox.bottomFlyInSpinBig();
      }
      this.isFinishMatchFinals = true; //完成比赛
      setTimeout(function () {
        that.isOverMask = true; //显示完成决赛遮罩
      }, 3000);
    },
    /**
     * 处理射击结束信号（冠军信号）
     */
    shoot_end: function () {
      var that = this;
      // 如果接收到该信号但当前靶道还没有完成射击
      if (this.isFinishMatchFinals == false) {
        this.$refs.rankBox.setRankAndColor(1);
      }
      this.isFinishMatchFinals = true; //完成比赛
      setTimeout(function () {
        that.isOverMask = true; //显示完成决赛遮罩
      }, 3000);
    },
    /**
     * 处理选手上膛信号，表示开始新的一发射击
     */
    signalShooting: function () {
      //当接收到上膛信号修改状态
      this.isShooting = true;
      //当接收到上膛信号画布重绘
      this.$refs.targetBox.repaint_all_points();
    },
  },
};
</script>

<style lang="less">
@import url("../assets/css/screen-show.css");
</style>