import store from '@/store';

// var host = "ws://" + window.location.hostname + ":9062/ws/athlete-view/athleteView/connect";.3.122
var host = "ws://nqys.top:9062/ws/athlete-view/athleteView/connect";
var ws;
var tt;
var lockReconnect = false; //避免重复连接

var websocket = {
    Init: function () {
        if ("WebSocket" in window) {
            ws = new WebSocket(host);
            console.log(window.location.hostname);
        } else if ("MozWebSocket" in window) {
            ws = new MozWebSocket(host);
        } else {
            console.log("您的浏览器不支持 WebSocket!");
            return;
        }

        ws.onmessage = function (e) {
            // console.log("接收消息:" + e.data);
            heartCheck.start();
            if (e.data == 'ok') { //心跳消息不做处理
                return
            }
            messageHandle(e.data);
        }

        ws.onclose = function () {
            console.log("连接已关闭");
            // 将连接状态存到vuex
            store.commit('change_link_state', false);
            reconnect();
        }

        ws.onopen = function () {
            console.log("连接成功");
            // 将连接状态存到vuex
            store.commit('change_link_state', true);
            heartCheck.start();
        }

        ws.onerror = function (e) {
            console.log("数据传输发生错误");
            // 将连接状态存到vuex
            store.commit('change_link_state', false);
            reconnect();
        }
    },
    Send: function (sender, reception, body, flag) {
        let data = {
            sender: sender,
            reception: reception,
            body: body,
            flag: flag
        }
        let msg = JSON.stringify(data);
        console.log("发送消息：" + msg);
        ws.send(msg);
    },
    getWebSocket() {
        return ws;
    },
    getStatus() {
        if (ws.readyState == 0) {
            return "未连接";
        } else if (ws.readyState == 1) {
            return "已连接";
        } else if (ws.readyState == 2) {
            return "连接正在关闭";
        } else if (ws.readyState == 3) {
            return "连接已关闭";
        }
    }
}

export default websocket;

//根据消息标识做不同的处理
function messageHandle(message) {
    let msg = JSON.parse(message);
    //服务端发送来的消息存到vuex
    store.commit('web_socket_msg', msg);
    sessionStorage.setItem("wsMsg", JSON.stringify(msg));
}

function reconnect() {
    if (lockReconnect) {
        return;
    };
    lockReconnect = true;
    //没连接上会一直重连，设置延迟避免请求过多
    tt && clearTimeout(tt);
    tt = setTimeout(function () {
        console.log("执行断线重连...");
        websocket.Init();
        lockReconnect = false;
    }, 500);
}

//心跳检测
var heartCheck = {
    timeout: 1000 * 60 * 5,
    timeoutObj: null,
    serverTimeoutObj: null,
    start: function () {
        console.log('开始心跳检测');
        var self = this;
        this.timeoutObj && clearTimeout(this.timeoutObj);
        this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
        this.timeoutObj = setTimeout(function () {
            //这里发送一个心跳，后端收到后，返回一个心跳消息，
            //onmessage拿到返回的心跳就说明连接正常
            console.log('心跳检测...');
            ws.send("HeartBeat");
            self.serverTimeoutObj = setTimeout(function () {
                if (ws.readyState != 1) {
                    ws.close();
                }
                // createWebSocket();
            }, self.timeout);

        }, this.timeout)
    }
}