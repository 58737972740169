<template>
  <router-view :key="$route.fullPath" />
</template>

<script>
import wsConnection from "./service/websocket";

export default {
  name: "App",
  created() {
    /**
     * 初始化websocket
     */
    wsConnection.Init();
  },
};
</script>

<style></style>
