<template>
  <div class="target-ele" id="target-ele" @click="downPdf">
    <div class="target-ele-left-text">{{ string }}</div>
    <div class="target-ele-right-text">{{ name }}</div>
    <br />
    <div class="target-ele-left-text">枪弹口径（4.50 mm）</div>
    <!-- <canvas id="target-type" class="target-canvas"></canvas> -->
    <DrawTargetComponent
      :shoot_type="shoot_type"
      :show_target_ring="show_target_ring"
    ></DrawTargetComponent>
    <DrawCurveComponent
      :shoot_type="shoot_type"
      :shoot_aim_curve_point_array="shoot_aim_curve_point_array"
      :isShooting="isShooting"
      :show_target_ring="show_target_ring"
    ></DrawCurveComponent>
    <DrawPointComponent
      :shoot_type="shoot_type"
      :shoot_point_array="shoot_point_array"
      :isShooting="isShooting"
      :show_target_ring="show_target_ring"
    ></DrawPointComponent>
  </div>
</template>

<script>
import DrawTargetComponent from "@/components/target/DrawTargetComponent.vue";
import DrawPointComponent from "@/components/target/DrawPointComponent.vue";
import DrawCurveComponent from "@/components/target/DrawCurveComponent.vue";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

export default {
  name: "TargetBox",
  components: {
    DrawTargetComponent,
    DrawPointComponent,
    DrawCurveComponent,
  },
  props: {
    string: {
      default: "",
      type: String,
    },
    name: {
      default: "",
      type: String,
    },
    shoot_type: {
      default: "",
      type: String,
    },
    show_target_ring: {
      default: 1,
      type: Number,
    },
    shoot_point_array: {
      default: [],
      type: Array,
    },
    shoot_aim_curve_point_array: {
      default: [],
      type: Array,
    },
    isShooting: {
      default: false,
      type: Boolean,
    },
  },
  mounted() {},
  watch: {
  },
  data() {
    return {
    };
  },
  methods: {
    downPdf() {
      let _this = this;
      document.documentElement.scrollTop = 0;
      let canvas = document.createElement("canvas"); // 生成canvas上下文
      let context = canvas.getContext("2d");
      let _articleHtml = document.getElementById("target-ele");
      console.log(_articleHtml.clientWidth,"client")
      document.body.style.height = "600px"
      document.body.style.width = "1024px"
      console.log(_articleHtml.clientWidth,"dsaaaaaaaaaa")
      let _w = _articleHtml.clientWidth; //获取需要导出pdf区域的宽度和高度
      let _h = _articleHtml.clientHeight; //;
      let scale = 3;
      if (_w > _h) {
        _h = _w;
      }
      canvas.width = _w*scale;
      canvas.height = _h*scale;
      context.fillStyle = "white"; // 或者使用十六进制颜色值 "#ffffff"
      context.fillRect(0, 0, canvas.width, canvas.height)
      context.scale(scale, scale);
      let opts = {
        scale: 1,
        width: _w, //dom 原始宽度
        height: _h,
        canvas: canvas,
        useCORS: true, //允许canvas画布内可以跨域请求外部链接图片, 允许跨域请求。
      };
      // 以上部分都是为了强化清晰度的，放大canvas画布
      setTimeout(() => {
        html2canvas(_articleHtml, opts).then((canvas) => {
        _this.createPdfAll(canvas, scale);
      });
      }, 220);
    },
    createPdfAll(canvas, scale) {
      const shoot_type = this.shoot_type;
      let content= shoot_type == "PISTOL"? 183:53.5;
      let pdf = new jsPDF("", "mm", [content, content]); //自定义宽高
      let pageData = canvas.toDataURL("image/jpeg", 1.0); //转换图片为dataURL
      pdf.addImage(pageData, "JPEG", 0, 0, content, content); //添加图像到页面
      pdf.save(`弹孔.pdf`);
      setTimeout(() => {
      document.body.style.height = "100vh"
      document.body.style.width = "100vw"
      console.log("aaaa")
      }, 500);
    },
  },
};
</script>

<style></style>
