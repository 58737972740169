<template>
  <div class="stage-ele">
    <div class="stage-ele-text">{{ shoot_model }}</div>
  </div>
</template>

<script>
export default {
  name: "MatchStageBox",
  props: {
    shoot_model: {
      default: "",
      type: String,
    },
  },
};
</script>

<style>
</style>