<template>
  <div class="main-top-right-bottom-bottom-block">
    <!--总计文字区域-->
    <!-- <div class="total-text-ele">
      <div class="total-text">总计</div>
    </div> -->
    <!--总分区域-->
    <div class="total-score-ele">
      <div class="total-num">{{ total_score.toFixed(1) }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TotalScoreBox",
  props: {
    total_score: {
      default: 0,
      type: Number,
    },
  },
};
</script>

<style>
</style>