<template>
  <div class="mask">
    <vueMiniPlayer ref="vueMiniPlayer" :video="video" :mutex="true" />
  </div>
</template>
<script>
export default {
  name: "adMaskBox",
  data() {
    return {
      video: {
        url: "",
        muted: false,
        loop: true,
        preload: "auto",
        poster: "",
        volume: 1,
        autoplay: true,
      },
    };
  },
  mounted() {
    this.video.url =
      "http://ad.nqys.top/a-view/" +
      sessionStorage.getItem("AdCompetitionId") +
      ".mp4?=" +
      Math.ceil(Date.now() / 30000);
      console.log(this.video.url);
  },
};
</script>
<style></style>
