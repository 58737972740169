import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    wsLinkState: false,
    wsMsg: null,
  },
  mutations: {
    change_link_state(state, bool) {
      state.wsLinkState = bool;
    },
    web_socket_msg(state, msg) {
      state.wsMsg = msg;
    },
  },
  actions: {},
  modules: {},
});
