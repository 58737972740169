<template>
  <div class="current-ring-ele">
    <div class="current-ring-ele-num">{{ newest_score.toFixed(1) }}</div>
  </div>
</template>

<script>
export default {
  name: "CurrentRingBox",
  props: {
    newest_score: {
      default: 0,
      type: Number,
    },
  },
};
</script>

<style>
</style>