var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"rank-ele"},[(_vm.ready_state)?_c('transition',[(_vm.ready_show)?_c('div',{staticClass:"rank-ele-text",style:({ color: _vm.text_color })},[_vm._v(" "+_vm._s(_vm.rank_text)+" ")]):_vm._e()]):_vm._e(),(_vm.is_testing)?_c('transition',[(_vm.testing_show)?_c('div',{staticClass:"rank-ele-text",style:({ color: _vm.text_color })},[_vm._v(" "+_vm._s(_vm.rank_text)+" ")]):_vm._e()]):_vm._e(),(_vm.is_formal)?_c('transition',[(_vm.start_show)?_c('div',{staticClass:"rank-ele-text",style:({ color: _vm.text_color })},[_vm._v(" "+_vm._s(_vm.rank_text)+" ")]):_vm._e()]):_vm._e(),(_vm.is_need_odd_finals)?_c('transition',[(_vm.need_odd_finals_show)?_c('div',{staticClass:"rank-ele-text",style:({ color: _vm.rank_color })},[_vm._v(" "+_vm._s(_vm.rank_text)+" ")]):_vm._e()]):_vm._e(),(_vm.is_odd_shoot_finals)?_c('transition',[(_vm.odd_shoot_finals_show)?_c('div',{staticClass:"rank-ele-text",style:({ color: _vm.rank_color })},[_vm._v(" "+_vm._s(_vm.rank_text)+" ")]):_vm._e()]):_vm._e(),(_vm.is_shoot_exit)?_c('transition',[(_vm.isTraining)?_c('div',{staticClass:"training-circle"}):(_vm.rank_circle_show)?_c('div',{staticClass:"rank-ele-circle",style:({ background: _vm.rank_color, animation: _vm.rank_animate_string })}):(_vm.rank_num_show && _vm.rank_num < 10)?_c('div',{staticClass:"rank-ele-num",style:({
        color: _vm.rank_color,
        animation: _vm.rank_animate_string,
        transform: _vm.one_digit_font_size,
      })},[_vm._v(" "+_vm._s(_vm.rank_num)+" ")]):(_vm.rank_num_show && _vm.rank_num >= 10)?_c('div',{staticClass:"rank-ele-num",style:({
        color: _vm.rank_color,
        animation: _vm.rank_animate_string,
        transform: _vm.two_digit_font_size,
      })},[_vm._v(" "+_vm._s(_vm.rank_num)+" ")]):_vm._e()]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }