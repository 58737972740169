<template>
  <div class="rank-ele">
    <transition v-if="ready_state">
      <div
        v-if="ready_show"
        class="rank-ele-text"
        :style="{ color: text_color }"
      >
        {{ rank_text }}
      </div>
    </transition>
    <transition v-if="is_testing">
      <div
        v-if="testing_show"
        class="rank-ele-text"
        :style="{ color: text_color }"
      >
        {{ rank_text }}
      </div>
    </transition>
    <transition v-if="is_formal">
      <div
        v-if="start_show"
        class="rank-ele-text"
        :style="{ color: text_color }"
      >
        {{ rank_text }}
      </div>
    </transition>
    <transition v-if="is_need_odd_finals">
      <div
        v-if="need_odd_finals_show"
        class="rank-ele-text"
        :style="{ color: rank_color }"
      >
        {{ rank_text }}
      </div>
    </transition>
    <transition v-if="is_odd_shoot_finals">
      <div
        v-if="odd_shoot_finals_show"
        class="rank-ele-text"
        :style="{ color: rank_color }"
      >
        {{ rank_text }}
      </div>
    </transition>
    <transition v-if="is_shoot_exit">
      <div v-if="isTraining" class="training-circle"></div>
      <div
        v-else-if="rank_circle_show"
        class="rank-ele-circle"
        :style="{ background: rank_color, animation: rank_animate_string }"
      ></div>
      <div
        v-else-if="rank_num_show && rank_num < 10"
        class="rank-ele-num"
        :style="{
          color: rank_color,
          animation: rank_animate_string,
          transform: one_digit_font_size,
        }"
      >
        {{ rank_num }}
      </div>
      <div
        v-else-if="rank_num_show && rank_num >= 10"
        class="rank-ele-num"
        :style="{
          color: rank_color,
          animation: rank_animate_string,
          transform: two_digit_font_size,
        }"
      >
        {{ rank_num }}
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "RankBox",
  props: {},
  data() {
    return {
      ready_state: false, //选手是否就绪
      is_testing: false, //是否试射状态
      is_formal: false, //是否正式射击
      is_need_odd_finals: false, //是否需要单发射击
      is_odd_shoot_finals: false, //是否正在单发射击
      is_shoot_exit: false, //是否出现射击成绩
      isTraining: false,
      //排名颜色
      rank_color_list: [
        "#FFFFFF",
        "#FF0000",
        "#FF7D00",
        "#FFFF00",
        "#00FF00",
        "#00FFFF",
        "#0000FF",
        "#FF00FF",
        "#FFC0CB",
        "#CCCCCC",
      ],
      rank_color: "",
      rank_num: 0,
      //多个动画
      animates_list: {
        1: "bigger", //变大
        2: "smaller", //变小
        3: "biggerSmaller", //变大变小
      },
      rank_animate_string: "",
      //排名数字大小
      one_digit_font_size: "scale(1, 1)",
      two_digit_font_size: "scale(0.5, 0.5)",
      //动画展示标识
      //排名区域文字颜色
      text_color_list: ["#FFFFFF", "#FF0000"],
      text_color: "",
      rank_text: "", //展示信息
      ready_show: false, //比赛即将开始
      testing_show: false, //正在试射
      start_show: false, //比赛开始
      need_odd_finals_show: false, //准备单发射击
      odd_shoot_finals_show: false, //正在单发射击
      rank_circle_show: false, //圆点展示
      rank_num_show: false, //数字展示
    };
  },
  methods: {
    /**
     * 传递rank
     */
    setRank: function (rank) {
      this.rank_num = rank;
    },
    /**
     * 赋值排名及相应颜色操作
     */
    setRankAndColor: function (rank) {
      if (rank > 8) {
        this.rank_color = "#CCCCCC";
      } else {
        this.rank_color = this.rank_color_list[rank];
      }
      var rankTransmitObject = {
        rank_num: this.rank_num,
        rank_color: this.rank_color,
      };
      // 回传父组件供显示决赛淘汰使用
      this.$emit("rank_and_color", rankTransmitObject);
    },
    /**
     * 比赛即将开始
     */
    readyText: function () {
      this.resetAni();
      this.ready_state = true;
      this.ready_show = true;
      this.text_color = this.text_color_list[0];
      this.rank_text = "即将开始";
    },
    /**
     * 训练模式动画
     */
    trainRank: function () {
      this.is_shoot_exit = true;
      this.isTraining = true;
      this.rank_animate_string = this.animates_list[3] + " 0.5s linear";
    },
    /**
     * 比赛模式默认圆圈
     */
    matchCircle: function () {
      this.ready_show = false;
      this.is_shoot_exit = true;
      this.rank_circle_show = true;
      this.rank_color = "#FFFFFF";
    },
    /**
     * 正在试射
     */
    testingText: function () {
      this.resetAni();
      this.is_testing = true;
      this.testing_show = true;
      this.text_color = this.text_color_list[0];
      this.rank_text = "正在试射";
    },
    /**
     * 正式射击
     */
    startText: function () {
      this.resetAni();
      this.is_formal = true;
      this.start_show = true;
      this.text_color = this.text_color_list[0];
      this.rank_text = "正式射击";
    },
    /**
     * 准备单发射击
     */
    needOddFinalsText: function () {
      this.ready_state = false;
      this.is_testing = false;
      this.is_formal = false;
      this.is_odd_shoot_finals = false;
      this.is_shoot_exit = false;
      this.text_color = this.text_color_list[1];
      this.rank_text = "准备单发决赛";
      this.rank_animate_string = "";
      this.ready_show = false;
      this.testing_show = false;
      this.start_show = false;
      this.rank_circle_show = false;
      this.rank_num_show = false;
      this.odd_shoot_finals_show = false;
      this.is_need_odd_finals = true;
      this.need_odd_finals_show = true;
    },
    /**
     * 正在单发决赛
     */
    oddShootFinalsText: function () {
      this.ready_state = false;
      this.is_testing = false;
      this.is_formal = false;
      this.is_need_odd_finals = false;
      this.is_shoot_exit = false;
      this.text_color = this.text_color_list[1];
      this.rank_text = "正在单发决赛";
      this.rank_animate_string = "";
      this.ready_show = false;
      this.testing_show = false;
      this.start_show = false;
      this.rank_circle_show = false;
      this.rank_num_show = false;
      this.need_odd_finals_show = false;
      this.is_odd_shoot_finals = true;
      this.odd_shoot_finals_show = true;
    },
    /**
     * 重置排名区域
     */
    resetAni: function () {
      this.ready_state = false;
      this.is_testing = false;
      this.is_formal = false;
      this.is_need_odd_finals = false;
      this.is_odd_shoot_finals = false;
      this.is_shoot_exit = false;
      this.rank_text = "";
      this.text_color = "";
      this.rank_num = 0;
      this.rank_color = "";
      this.rank_animate_string = "";
      this.ready_show = false;
      this.testing_show = false;
      this.start_show = false;
      this.rank_circle_show = false;
      this.rank_num_show = false;
      this.need_odd_finals_show = false;
      this.odd_shoot_finals_show = false;
    },
    /**
     * 显示排名
     */
    display_rank: function () {
      this.ready_state = false;
      this.is_testing = false;
      this.is_formal = false;
      this.is_need_odd_finals = false;
      this.is_odd_shoot_finals = false;
      this.ready_show = false;
      this.testing_show = false;
      this.start_show = false;
      this.need_odd_finals_show = false;
      this.odd_shoot_finals_show = false;
      this.is_shoot_exit = true;
      this.rank_num_show = true;
    },
    /**
     * 决赛结束
     */
    matchOver: function () {
      this.ready_state = false;
      this.is_testing = false;
      this.is_formal = false;
      this.is_need_odd_finals = false;
      this.is_odd_shoot_finals = false;
      this.rank_text = "";
      this.rank_animate_string = "";
      this.ready_show = false;
      this.testing_show = false;
      this.start_show = false;
      this.need_odd_finals_show = false;
      this.odd_shoot_finals_show = false;
      this.is_shoot_exit = true;
    },
    /**
     * 决赛继续
     */
    continueMatch: function () {
      this.ready_state = false;
      this.is_testing = false;
      this.is_formal = false;
      this.is_need_odd_finals = false;
      this.is_odd_shoot_finals = false;
      this.rank_text = "";
      this.rank_animate_string = "";
      this.ready_show = false;
      this.testing_show = false;
      this.start_show = false;
      this.need_odd_finals_show = false;
      this.odd_shoot_finals_show = false;
      this.is_shoot_exit = true;
      this.rank_num_show = true;
    },
    /**
     * 显示排名动画
     */
    rank_animation: function () {
      // 圆圈变数字动画
      var that = this;
      this.rank_animate_string = this.animates_list[2] + " 0.5s linear";
      that.rank_circle_show = true;
      that.rank_num_show = false;

      // 数字变圆圈动画（定时0.5秒）
      setTimeout(function () {
        that.setRankAndColor(that.rank_num);
        that.rank_circle_show = false;
        that.rank_num_show = true;
        that.rank_animate_string = that.animates_list[1] + " 0.5s linear";
      }, 500);

      // 圆圈变数字动画（定时2秒）
      setTimeout(function () {
        that.rank_animate_string = that.animates_list[2] + " 0.5s linear";
      }, 2000);

      // 设置圆圈默认为白色（定时2.5）
      setTimeout(function () {
        that.setRankAndColor(0);
        that.rank_circle_show = true;
        that.rank_num_show = false;
        that.rank_animate_string = that.animates_list[1] + " 0.5s linear";
      }, 2500);
    },
  },
};
</script>

<style></style>
