<template>
  <canvas id="target-type" class="target-canvas"></canvas>
</template>

<script>
export default {
  name: "DrawTargetComponent",
  props: {
    shoot_type: {
      default: "",
      type: String,
    },
    show_target_ring: {
      default: 0,
      type: Number,
    },
  },

  mounted() {
    this.drawTarget();
    // 监听shoot_type，值传递过来后触发绘图函数
    this.$watch("shoot_type", () => {
      this.drawTarget();
    });
    // 在页面mounted时，挂载window.onresize方法，实现页面自适应
    const that = this;
    // window.addEventListener("resize", () => {
    //   return (
    //     (() => {
    //       // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
    //       if (!that.timer) {
    //         that.timer = true;
    //         setTimeout(function () {
    //           // 重新赋值最新窗口数据
    //           that.canvas_width = (document.body.clientWidth - 8) * 0.8 * 0.725;
    //           that.canvas_height = (document.body.clientHeight - 6) * 0.88;
    //           that.drawTarget();
    //           that.timer = false;
    //         }, 200);
    //       }
    //     })(),
    //     false
    //   );
    // });
    // 打印功能需要重新修改
    const observer = new ResizeObserver ((entries)=>{
        if (!that.timer) {
            that.timer = true;
            setTimeout(function () {
                console.log("aaaaaaaaaaaaa")
              // 重新赋值最新窗口数据
            that.canvas_width = (document.body.clientWidth - 8) * 0.8 * 0.725;
            that.canvas_height = (document.body.clientHeight - 6) * 0.88;
            that.drawTarget();
              that.timer = false;
            }, 200);
          }
    });
    observer.observe(document.body);
  },
  watch: {
    // 监听显示环值重绘靶图
    show_target_ring: function () {
      this.drawTarget();
    },
  },
  data() {
    return {
      canvas_width: (document.body.clientWidth - 6) * 0.8 * 0.725,
      canvas_height: (document.body.clientHeight - 4) * 0.88,
      rifleTenRingDiameter: 0.5, //步枪十环直径
      rifleRingDiameterDiff: 5, //步枪环直径差
      pistolTenRingDiameter: 11.5, //手枪十环直径
      pistolRingDiameterDiff: 16, //手枪环直径差
      rifle_target_scale_ratio: 45.5 / 15.5, //步枪初始缩放比例
      pistol_target_scale_ratio: 155.5 / 59.5, //手枪初始缩放比例
      timer: false, // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
    };
  },
  methods: {
    /**
     * 绘制靶图
     */
    drawTarget: function () {
      var changeMultiple;
      if (this.shoot_type == "RIFLE") {
        changeMultiple =
          (this.rifleTenRingDiameter +
            (10 - this.show_target_ring) * this.rifleRingDiameterDiff) /
          45.5;
        //将缩放比例赋值Vue
        this.rifle_target_scale_ratio = 1 / changeMultiple;
        this.drawRifleTarget();
      } else if (this.shoot_type == "PISTOL") {
        changeMultiple =
          (this.pistolTenRingDiameter +
            (10 - this.show_target_ring) * this.pistolRingDiameterDiff) /
          155.5;
        //将缩放比例赋值Vue
        this.pistol_target_scale_ratio = 1 / changeMultiple;
        this.drawPistolTarget();
      }
    },
    /**
     * 绘制步枪靶
     */
    drawRifleTarget: function () {
      var scale = this.rifle_target_scale_ratio; //获取缩放比例
      var targetBoxWidth =
        document.getElementsByClassName("target-ele")[0].clientWidth; //获取div宽度，决定靶心偏移量
      var canvasRifle = document.getElementById("target-type");
      canvasRifle.setAttribute("width", this.canvas_width * scale);
      canvasRifle.setAttribute("height", this.canvas_height * scale);
      var ctx = canvasRifle.getContext("2d");
      // 加载前清空画布
      ctx.clearRect(0, 0, canvasRifle.clientWidth, canvasRifle.clientHeight);
      var w = canvasRifle.clientWidth / 2; //根据(0, 0)点横坐标需要移动的距离
      var h = canvasRifle.clientHeight / 2; //根据(0, 0)点纵坐标需要移动的距离
      ctx.translate(-(w - targetBoxWidth / 2), 0); //缩放横移
      var n = 9; //画圆数目
      var spacing = 5; //标准比例环半径差
      var ring_height = canvasRifle.clientHeight * 0.96; //核心区域高
      var ring_radius = ring_height / 2; //核心区域最外圈半径

      var rifle_ring_unit = ring_height * (0.5 / 45.5); //核心区域标准比例的最小单位长度（步枪）
      for (var i = 0; i < n; i++) {
        ctx.beginPath(); //开始一个新的绘制路径
        ctx.fillStyle = "transparent"; //填充透明
        ctx.arc(
          w,
          h,
          ring_radius - rifle_ring_unit * spacing * i,
          0,
          Math.PI * 2
        );
        ctx.fill();
        ctx.font = "normal bold " + w / 17 + "px Source Han Sans CN,serif";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "#FFFFFF";
        if (i + 1 < n) {
          ctx.fillText(
            (i + 1).toString(),
            w,
            -(ring_radius - rifle_ring_unit * spacing * i - (w / 25) * scale) +
              h
          ); //上环数
          ctx.fillText(
            (i + 1).toString(),
            -(ring_radius - rifle_ring_unit * spacing * i - (w / 25) * scale) +
              w,
            h
          ); //左环数
          ctx.fillText(
            (i + 1).toString(),
            ring_radius - rifle_ring_unit * spacing * i - (w / 25) * scale + w,
            h
          ); //右环数
          ctx.fillText(
            (i + 1).toString(),
            w,
            ring_radius - rifle_ring_unit * spacing * i - (w / 25) * scale + h
          ); //下环数
        }
        ctx.lineWidth = 2; //边框
        ctx.strokeStyle = "white";
        ctx.stroke();
      }
      // 画步枪靶圆心
      ctx.beginPath(); //开始一个新的绘制路径
      ctx.fillStyle = "white"; //填充透明
      ctx.arc(w, h, rifle_ring_unit * 0.5, 0, Math.PI * 2);
      ctx.fill();
    },
    /**
     * 绘制手枪靶
     */
    drawPistolTarget: function () {
      var scale = this.pistol_target_scale_ratio; //获取缩放比例
      var targetBoxWidth =
        document.getElementsByClassName("target-ele")[0].clientWidth; //获取外层div宽度，决定靶心偏移量
      var canvasPistol = document.getElementById("target-type");
      canvasPistol.setAttribute("width", this.canvas_width * scale);
      canvasPistol.setAttribute("height", this.canvas_height * scale);
      var ctx = canvasPistol.getContext("2d");
      // 加载前清空画布
      ctx.clearRect(0, 0, canvasPistol.clientWidth, canvasPistol.clientHeight);
      var w = canvasPistol.clientWidth / 2; //根据(0, 0)点横坐标需要移动的距离
      var h = canvasPistol.clientHeight / 2; //根据(0, 0)点纵坐标需要移动的距离
      ctx.translate(-(w - targetBoxWidth / 2), 0); //缩放横移
      var n = 9; //画圆数目
      var spacing = 16; //标准比例环半径差
      var ring_height = canvasPistol.clientHeight * 0.96; //核心区域高
      var ring_radius = ring_height / 2; //核心区域最外圈半径

      var pistol_ring_unit = ring_height * (0.5 / 155.5); //核心区域标准比例的最小单位长度（手枪）
      for (var i = 0; i < n; i++) {
        ctx.beginPath(); //开始一个新的绘制路径
        ctx.fillStyle = "transparent"; //填充透明
        ctx.arc(
          w,
          h,
          ring_radius - pistol_ring_unit * spacing * i,
          0,
          Math.PI * 2
        );
        ctx.fill();
        ctx.font = "normal bold " + w / 20 + "px Source Han Sans CN,serif";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillStyle = "#FFFFFF";
        if (i + 1 < n) {
          ctx.fillText(
            (i + 1).toString(),
            w,
            -(ring_radius - pistol_ring_unit * spacing * i - (w / 25) * scale) +
              h
          ); //上环数
          ctx.fillText(
            (i + 1).toString(),
            -(ring_radius - pistol_ring_unit * spacing * i - (w / 25) * scale) +
              w,
            h
          ); //左环数
          ctx.fillText(
            (i + 1).toString(),
            ring_radius - pistol_ring_unit * spacing * i - (w / 25) * scale + w,
            h
          ); //右环数
          ctx.fillText(
            (i + 1).toString(),
            w,
            ring_radius - pistol_ring_unit * spacing * i - (w / 25) * scale + h
          ); //下环数
        }
        ctx.lineWidth = 2; //边框
        ctx.strokeStyle = "white";
        ctx.stroke();
      }
      // 画手枪靶外圆心
      ctx.beginPath(); //开始一个新的绘制路径
      ctx.fillStyle = "transparent"; //填充透明
      ctx.arc(w, h, pistol_ring_unit * 11.5, 0, Math.PI * 2);
      ctx.fill();
      ctx.lineWidth = 2; //边框
      ctx.strokeStyle = "white";
      ctx.stroke();
      // 画手枪内圆心
      ctx.beginPath(); //开始一个新的绘制路径
      ctx.fillStyle = "transparent"; //填充透明
      ctx.arc(w, h, pistol_ring_unit * 5, 0, Math.PI * 2);
      ctx.fill();
      ctx.lineWidth = 2; //边框
      ctx.strokeStyle = "white";
      ctx.stroke();
    },
  },
};
</script>

<style></style>
