<template>
  <canvas id="shoot-point" class="point-canvas"></canvas>
</template>

<script>
export default {
  name: "DrawPointComponent",
  props: {
    shoot_type: {
      default: "",
      type: String,
    },
    show_target_ring: {
      default: 0,
      type: Number,
    },
    shoot_point_array: {
      default: [],
      type: Array,
    },
    isShooting: {
      default: false,
      type: Boolean,
    },
  },
  mounted() {
    this.repaint_all_points();
    // 在页面mounted时，挂载window.onresize方法，实现页面自适应
    const that = this;
    // window.addEventListener("resize", () => {
    //   return (
    //     (() => {
    //       // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
    //       if (!that.timer) {
    //         that.timer = true;
    //         setTimeout(function () {
    //           // 重新赋值最新窗口数据
    //           that.canvas_width = (document.body.clientWidth - 8) * 0.8 * 0.725;
    //           that.canvas_height = (document.body.clientHeight - 6) * 0.88;
    //           that.repaint_all_points();
    //           that.timer = false;
    //         }, 200);
    //       }
    //     })(),
    //     false
    //   );
    // });
     // 打印功能需要重新修改
    const observer = new ResizeObserver ((entries)=>{

        if (!that.timer) {
            that.timer = true;
            setTimeout(function () {
              // 重新赋值最新窗口数据
              that.canvas_width = (document.body.clientWidth - 8) * 0.8 * 0.725;
              that.canvas_height = (document.body.clientHeight - 6) * 0.88;
              that.repaint_all_points();
              that.timer = false;
            }, 200);
          }
    });
    observer.observe(document.body);
  },
  watch: {
    // 监听显示环值重绘弹孔
    show_target_ring: function () {
      this.draw_point();
    },
    // 监听射击数据发生变化执行重绘
    shoot_point_array: function () {
      this.draw_point();
    },
    // 监听上膛信号执行重绘
    isShooting: function () {
      this.draw_point();
    },
  },
  data() {
    return {
      canvas_width: (document.body.clientWidth - 8) * 0.8 * 0.725,
      canvas_height: (document.body.clientHeight - 6) * 0.88,
      rifleTenRingDiameter: 0.5, //步枪十环直径
      rifleRingDiameterDiff: 5, //步枪环直径差
      pistolTenRingDiameter: 11.5, //手枪十环直径
      pistolRingDiameterDiff: 16, //手枪环直径差
      rifle_target_scale_ratio: 45.5 / 15.5, //步枪初始缩放比例
      pistol_target_scale_ratio: 155.5 / 59.5, //手枪初始缩放比例
      timer: false, // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
    };
  },
  methods: {
    draw_point: function () {
      var changeMultiple;
      if (this.shoot_type == "RIFLE") {
        changeMultiple =
          (this.rifleTenRingDiameter +
            (10 - this.show_target_ring) * this.rifleRingDiameterDiff) /
          45.5;
        //将缩放比例赋值Vue
        this.rifle_target_scale_ratio = 1 / changeMultiple;
      } else if (this.shoot_type == "PISTOL") {
        changeMultiple =
          (this.pistolTenRingDiameter +
            (10 - this.show_target_ring) * this.pistolRingDiameterDiff) /
          155.5;
        //将缩放比例赋值Vue
        this.pistol_target_scale_ratio = 1 / changeMultiple;
      }
      this.repaint_all_points();
    },
    /**
     * 绘制所有射击点
     */
    repaint_all_points: function () {
      // 如果数据为空直接退出方法
      if (this.shoot_point_array.length === 0) {
        return;
      }
      var scale; //获取缩放比例
      if (this.shoot_type == "RIFLE") {
        scale = this.rifle_target_scale_ratio;
      } else if (this.shoot_type == "PISTOL") {
        scale = this.pistol_target_scale_ratio;
      }
      var targetBoxWidth =
        document.getElementsByClassName("target-ele")[0].clientWidth; //获取外层div宽度，决定靶心偏移量
      var canvasPoint = document.getElementById("shoot-point");
      canvasPoint.setAttribute("width", this.canvas_width * scale);
      canvasPoint.setAttribute("height", this.canvas_height * scale);
      var ctx = canvasPoint.getContext("2d");
      // 加载前清空画布
      ctx.clearRect(0, 0, canvasPoint.clientWidth, canvasPoint.clientHeight);
      var w = canvasPoint.clientWidth / 2; //根据(0, 0)点横坐标需要移动的距离
      var h = canvasPoint.clientHeight / 2; //根据(0, 0)点纵坐标需要移动的距离
      ctx.translate(-(w - targetBoxWidth / 2), 0); //缩放横移
      // 定义射击点边框、半径、坐标参数
      let gun_name = 1
      let hue = "transparent"
      if(sessionStorage.getItem("gun_name")=="air_gun"&&this.shoot_type == "RIFLE"){ 
         
         gun_name = 1.10
      }else if(sessionStorage.getItem("gun_name")=="air_gun"&&this.shoot_type == "PISTOL"){
        gun_name = 1.021
      }
      var border;
      var radius;
      var coordinate;
      var ring_height = canvasPoint.clientHeight*0.96; //核心区域高
      var ring_radius = ring_height / 2*gun_name; //核心区域最外圈半径
      var rifle_ring_unit = ring_height * (0.5 / 45.5); //核心区域标准比例的最小单位长度（步枪）
      var pistol_ring_unit = ring_height * (0.5 / 155.5); //核心区域标准比例的最小单位长度（手枪）
      if (this.shoot_type == "RIFLE") {
        border = 7 * scale;
        radius = 4.5 * rifle_ring_unit - border;
      } else if (this.shoot_type == "PISTOL") {
        border = 2 * scale;
        radius = 4.5 * pistol_ring_unit - border;
      }
      // 指定填充颜色
      ctx.lineWidth = border * 2; //射击点边框
      
      for (var i = 0; i < this.shoot_point_array.length; i++) {
        coordinate = {
          x: ring_radius * this.shoot_point_array[i].x + w,
          y: ring_radius * this.shoot_point_array[i].y + h,
        };
        ctx.beginPath();
        ctx.arc(coordinate.x, coordinate.y, radius, 0, Math.PI * 2);
        // ctx.fill();
        // 判断当前是否正在一发射击状态
        if (this.isShooting) {
          ctx.strokeStyle = "#00ff00";
          ctx.stroke();
        } else {
          if (i === this.shoot_point_array.length - 1) {
            ctx.fillStyle = sessionStorage.getItem("gun_name")=="air_gun"? "#ff0000":hue; //透明
            ctx.strokeStyle = "#ff0000";
            ctx.stroke();
            ctx.fill();
          } else {
            console.log(i,this.shoot_point_array.length - 1)
            ctx.fillStyle = sessionStorage.getItem("gun_name")=="air_gun"? "#00ff00":hue; //透明
            ctx.strokeStyle = "#00ff00";
            ctx.stroke();
            ctx.fill();
          }
        }
      }
      console.log(ctx.fillStyle )
    },
  },
};
</script>

<style>
</style>