<template>
  <div class="current-order-ele">
    <div class="current-order-ele-num">
      发序：{{ current_order < 10 ? "0" + current_order : current_order }}
    </div>
  </div>
</template>

<script>
export default {
  name: "CurrentOrderBox",
  props: {
    current_order: {
      default: 0,
      type: Number,
    },
  },
};
</script>

<style>
</style>