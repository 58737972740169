<template>
  <div>
    <AllShootsParentBox
      v-for="(item, index) in shoot_data_show"
      :item="item"
      :key="index"
    ></AllShootsParentBox>
  </div>
</template>

<script>
import AllShootsParentBox from "@/components/list/AllShootsParentBox.vue";

export default {
  name: "AllShootsBox",
  props: {
    isOddShootingFinals: {
      default: false,
      type: Boolean,
    },
    isRecoveringData: {
      default: false,
      type: Boolean,
    },
    shoot_model: {
      default: "",
      type: String,
    },
  },
  components: {
    AllShootsParentBox,
  },
  data() {
    return {
      shoot_data_show: [], //展示当前靶道的数据进行分组后的集合数组
      //多个分组文字
      group_text_list: {
        1: "第一组",
        2: "第二组",
        3: "第三组",
        4: "第四组",
        5: "第五组",
        6: "第六组",
        7: "第七组",
        8: "第八组",
        9: "第九组",
        10: "第十组",
      },
    };
  },
  methods: {
    /**
     * 选择分组显示模式
     */
    chooseAddModel: function (list) {
      switch (this.shoot_model) {
        case "训练":
          this.orderAddTraining(list);
          break;
        case "比赛":
          this.orderAddQualifying(list);
          break;
        case "比赛":
          this.orderAddFinals(list);
          break;
        default:
          break;
      }
    },
    /**
     * 训练模式分组显示成绩
     */
    orderAddTraining: function (orderList) {
      var that = this;
      var no = orderList.orderNo;
      var groupString = "第" + (parseInt((no - 1) / 10) + 1) + "组";
      var result = this.shoot_data_show.find(
        (item) => item.orderGroupName == groupString
      );
      if (result != null) {
        result.scoreData.push(orderList);
        result.groupSum += orderList.shootScore;
      } else {
        var groupList = {
          orderGroupName: "", //发序分组名
          scoreData: [], //分组射击数据集
          groupSum: 0, //分组小计成绩
        };
        groupList.orderGroupName = groupString;
        groupList.scoreData.push(orderList);
        groupList.groupSum += orderList.shootScore;
        this.$nextTick(function () {
          that.hideClass(10);
        });
        this.shoot_data_show.push(groupList);
        // 训练模式下投显总是保留6组数据显示
        if (this.shoot_data_show.length > 6) {
          this.shoot_data_show = this.shoot_data_show.splice(-6, 6);
        }
      }
    },
    /**
     * 资格赛分组显示成绩
     */
    orderAddQualifying: function (orderList) {
      var that = this;
      var no = orderList.orderNo;
      var groupString = this.group_text_list[parseInt((no - 1) / 10) + 1];
      var result = this.shoot_data_show.find(
        (item) => item.orderGroupName == groupString
      );
      if (result != null) {
        result.scoreData.push(orderList);
        result.groupSum += orderList.shootScore;
      } else {
        var groupList = {
          orderGroupName: "", //发序分组名
          scoreData: [], //分组射击数据集
          groupSum: 0, //分组小计成绩
        };
        groupList.orderGroupName = groupString;
        groupList.scoreData.push(orderList);
        groupList.groupSum += orderList.shootScore;
        this.$nextTick(function () {
          that.hideClass(10);
        });
        this.shoot_data_show.push(groupList);
      }
    },
    /**
     * 决赛分组显示成绩
     */
    orderAddFinals: function (orderList) {
      var that = this;
      if (!this.isOddShootingFinals) {
        var no = orderList.orderNo;
        var result;
        var groupList;
        var groupString1 = this.group_text_list[parseInt((no - 1) / 5) + 1];
        var groupString2 = this.group_text_list[parseInt((no - 11) / 2) + 3];
        if (parseInt(no) <= 10) {
          result = this.shoot_data_show.find(
            (item) => item.orderGroupName == groupString1
          );
          if (result != null) {
            result.scoreData.push(orderList);
            result.groupSum += orderList.shootScore;
          } else {
            groupList = {
              orderGroupName: "", //发序分组名
              scoreData: [], //分组射击数据集
              groupSum: 0, //分组小计成绩
            };
            groupList.orderGroupName = groupString1;
            groupList.scoreData.push(orderList);
            groupList.groupSum += orderList.shootScore;
            this.$nextTick(function () {
              that.hideClass(5);
            });
            this.shoot_data_show.push(groupList);
          }
        } else {
          result = this.shoot_data_show.find(
            (item) => item.orderGroupName == groupString2
          );
          if (result != null) {
            result.scoreData.push(orderList);
            result.groupSum += orderList.shootScore;
          } else {
            groupList = {
              orderGroupName: "", //发序分组名
              scoreData: [], //分组射击数据集
              groupSum: 0, //分组小计成绩
            };
            groupList.orderGroupName = groupString2;
            groupList.scoreData.push(orderList);
            groupList.groupSum += orderList.shootScore;
            this.$nextTick(function () {
              that.hideClass(2);
            });
            this.shoot_data_show.push(groupList);
          }
        }
      } else {
        //处理单发射击列表显示
        var len = this.shoot_data_show.length;
        console.log(len);
        this.shoot_data_show[len - 1].scoreData.push(orderList);
      }
    },
    /**
     * 隐藏记录的方法
     */
    hideClass: function (shootCount) {
      var classArray = $(".shoots-list-ele-bottom-text");
      var hideNum;
      if (this.isRecoveringData) {
        hideNum = parseInt((classArray.length - 1) / shootCount) * shootCount;
        for (var i = 0; i < hideNum; i++) {
          classArray.eq(i).hide();
        }
      } else {
        hideNum = classArray.length - 1;
        for (var j = 0; j < hideNum; j++) {
          classArray.eq(j).hide();
        }
      }
    },
    /**
     * 清空列表中所有数据
     */
    clearGroupListData: function () {
      this.shoot_data_show = [];
    },
  },
};
</script>

<style>
</style>