import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import $ from 'jquery'
import axios from "axios"
import animated from "animate.css"
import vueMiniPlayer from 'vue-mini-player'
import 'vue-mini-player/lib/vue-mini-player.css'
Vue.use(vueMiniPlayer)

Vue.use(animated)
Vue.prototype.$ = $
// axios.defaults.baseURL = 'http://'+window.location.hostname+':9062'
axios.defaults.baseURL = 'http://192.168.3.122:9062'
Vue.prototype.$axios = axios

router.beforeEach((to, from, next) => {
    /* 路由发生变化修改页面title */
    if (to.meta.title) {
        document.title = "头显"
    }
    next()
})

Vue.config.productionTip = false

new Vue({
    router,
    store,
    data() {
        return {};
    },
    render: h => h(App)
}).$mount('#app')