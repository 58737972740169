<template>
  <div class="athlete-ele-team">
    <div class="athlete-ele-team-text">{{ player_info.athleteTeam }}</div>
  </div>
</template>

<script>
export default {
  name: "PlayerTeamBox",
  props: {
    player_info: {
      default: {},
      type: Object,
    },
  },
};
</script>

<style>
</style>