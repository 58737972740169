<template>
  <!-- 总体布局 -->
  <div class="all" v-cloak>
    <!-- 选手决赛完成遮罩区域 -->
    <FinishFinalsMaskBox
      v-if="isOverMask"
      :player_info="vue_playerInfo"
      :rank_num="rank_num"
      :team="team"
      :total_score="total_score"
    ></FinishFinalsMaskBox>
    <adMaskBox v-if="isAdMask"></adMaskBox>
    <!-- 主体区域 -->
    <div class="main">
      <div class="main-top-block">
        <div class="main-top-left-block">
          <!-- 靶区域 -->
          <TargetBox
            :string="target_type_string"
            :name="competitionName"
            :shoot_type="shoot_type"
            :show_target_ring="show_target_ring"
            :shoot_point_array="shoot_point_array"
            :shoot_aim_curve_point_array="shoot_aim_curve_point_array"
            :isShooting="isShooting"
            ref="targetBox"
          ></TargetBox>
          <div class="main-top-left-right-block">
            <!-- 赛事阶段区域 -->
            <MatchStageBox :shoot_model="shoot_model"></MatchStageBox>
            <!-- 所有射击成绩区域 -->
            <div class="shoots-list-ele">
              <AllShootsBox
                :shoot_model="shoot_model"
                :isOddShootingFinals="isOddShootingFinals"
                :isRecoveringData="isRecoveringData"
                ref="listBox"
              ></AllShootsBox>
            </div>
            <!-- 射击发数区域 -->
            <CurrentOrderBox :current_order="current_order"></CurrentOrderBox>
          </div>
        </div>
        <div class="main-top-right-block">
          <!-- 排名区域 -->
          <RankBox
            ref="rankBox"
            v-on:rank_and_color="setRankAndColor"
          ></RankBox>
          <div class="main-top-right-bottom-block">
            <!-- 当前环数区域 -->
            <CurrentRingBox :newest_score="newest_score"></CurrentRingBox>
            <!-- 总计成绩区域 -->
            <TotalScoreBox :total_score="total_score"></TotalScoreBox>
          </div>
        </div>
      </div>
      <div class="main-bottom">
        <!-- 分组靶道信息区域 -->
        <GroupAndTargetBox :team="team" :number="number"></GroupAndTargetBox>
        <!-- 选手信息区域 -->
        <div class="athlete-ele">
          <!-- 选手姓名区域 -->
          <PlayerNameBox :player_info="vue_playerInfo"></PlayerNameBox>
          <!-- 选手团队区域 -->
          <PlayerTeamBox :player_info="vue_playerInfo"></PlayerTeamBox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FinishFinalsMaskBox from "@/components/mask/FinishFinalsMaskBox.vue";
import adMaskBox from "@/components/mask/adMaskBox.vue";
import TargetBox from "@/components/target/TargetBox.vue";
import MatchStageBox from "@/components/stage/MatchStageBox.vue";
import AllShootsBox from "@/components/list/AllShootsBox.vue";
import CurrentOrderBox from "@/components/order/CurrentOrderBox.vue";
import RankBox from "@/components/rank/RankBox.vue";
import CurrentRingBox from "@/components/ring/CurrentRingBox.vue";
import TotalScoreBox from "@/components/total/TotalScoreBox.vue";
import GroupAndTargetBox from "@/components/group/GroupAndTargetBox.vue";
import PlayerNameBox from "@/components/player/PlayerNameBox.vue";
import PlayerTeamBox from "@/components/player/PlayerTeamBox.vue";
import { async } from "q";

export default {
  name: "AthleteScreen",
  components: {
    // MatchOverMaskBox,
    FinishFinalsMaskBox,
    adMaskBox,
    TargetBox,
    MatchStageBox,
    AllShootsBox,
    CurrentOrderBox,
    RankBox,
    CurrentRingBox,
    TotalScoreBox,
    GroupAndTargetBox,
    PlayerNameBox,
    PlayerTeamBox,
  },
  created() {},
  computed: {
    getWsLinkState() {
      return this.$store.state.wsLinkState;
    },
    getWsMsg() {
      this.signalHandle();
      return this.$store.state.wsMsg;
    },
  },
  watch: {
    /**
     * 监听websocket连接状态
     */
    getWsLinkState: function (result) {
      if (result) {
        return;
      } else {
        console.log(window.location.href);
        this.$router.push({
          name: "QRScreen",
          query: {
            account: this.$route.query.account,
          },
        });
      }
    },
    /**
     * 监听websocket获取数据的全局变量
     */
    getWsMsg: function (result) {},
  },
  mounted() {
    if (window.name == "") {
      console.log("首次被加载");
      window.name = "isReload";
    }
  },
  data() {
    return {
      qrParams: {
        // realName: "刘泽成",
        // shootingDataList: [],
        // training: {
        //   averageScore: 0,
        //   creator: 1493154979635138600,
        //   event: "RIFLE",
        //   id: 1496742784189730800,
        //   quantity: 0,
        //   standardDeviation: 0,
        //   startTimestamp: 1645686262554,
        //   status: "ON_GOING",
        //   totalScore: 0,
        // },
        // type: "ON_BIND",
      }, // 扫码跳转数据
      competitionId: "", // 比赛ID
      competitionName: "", // 比赛名称
      shoot_type: "", // 比赛类型
      shoot_model: "", // 射击模式
      target_type_string: "", // 靶区域字符串
      current_order: 0, // 当前发序
      shoot_count: 0, // 射击数量
      newest_score: 0, // 最新成绩
      total_score: 0, // 总成绩
      avg_score: 0, // 平均成绩
      standard_deviation: 0, // 标准差
      vue_playerInfo: {
        athleteId: "", // 选手ID
        athleteName: "", // 选手姓名
        athleteNation: "", // 选手国籍
        athleteTeam: "", // 选手团队
      }, // 选手信息
      team: 0, // 当前分组
      shoot_recover_data: [], // 历史射击数据数组
      shoot_point_array: [], // 所有展示在靶内的击发点坐标集合数组
      shoot_aim_curve_point_array: [], // 最新瞄准曲线点坐标集合数组
      shoot_pressure_value_array: [], // 最新压力曲线压力值集合数组
      shoot_fire_pressure: 0, // 最新击发点压力值
      shoot_exit_rank_array: [], // 所有存在射击的靶道排名集合数组
      show_target_ring: 1, // 靶图显示到几环
      isOverMask: false, // 是否显示遮罩，默认未完成决赛且不显示遮罩
      isRecoveringData: false, // 是否正在恢复数据
      isNeedOddFinals: false, // 该靶道是否需要准备单发射击，默认为false
      isOddShootingFinals: false, // 是否正在进行单发射击决赛，默认为false
      isFinishMatchFinals: false, // 是否完成比赛决赛或决赛被淘汰，默认为false
      isShooting: false, // 当前靶道是否已完成上膛正在进行射击（弹孔颜色变化使用）
      isTesting: false, // 是否正在进行试射
      rank_num: 0,
      mask_rank_color: "",
      number: 0, // 靶道
      venue_num: 0, // 场馆
      team: 0,
      ranking: [], //恢复排名数据
      AdCompetitionId: "",
      isAdMask: false,
    };
  },
  methods: {
    /**
     * 接收回传的排名及颜色对象并赋值
     */
    setRankAndColor: function (result) {
      this.rank_num = result.rank_num;
    },
    /**
     * 清空试射数据操作
     */
    clearTestingData: function () {
      // 清空射击点坐标集合数组
      this.shoot_point_array = [];
      // 清空最新瞄准曲线坐标集合数组
      this.shoot_aim_curve_point_array = [];
      // 清空最新压力曲线压力值集合数组
      this.shoot_pressure_value_array = [];
      // 重置击发点压力值
      this.shoot_fire_pressure = 0;
      // 清空射击点
      this.cleanPoint();
      // 清空列表中所有射击数据
      this.$refs.listBox.clearGroupListData();
      // 重置当前发序
      this.current_order = 0;
      // 重置当前环数区域
      this.newest_score = 0;
      // 重置总成绩区域
      this.total_score = 0;
    },
    /**
     * 清空靶图射击点
     */
    cleanPoint: function () {
      var canvasReset = document.getElementById("shoot-point");
      var ctx = canvasReset.getContext("2d");
      ctx.clearRect(0, 0, canvasReset.clientWidth, canvasReset.clientHeight);
    },
    /**
     * 服务器断线重连清空数据操作
     */
    clearDisconnectData: function () {
      // 清空射击点坐标集合数组
      this.shoot_point_array = [];
      // 清空最新瞄准曲线坐标集合数组
      this.shoot_aim_curve_point_array = [];
      // 清空最新压力曲线压力值集合数组
      this.shoot_pressure_value_array = [];
      // 重置击发点压力值
      this.shoot_fire_pressure = 0;
      // 清空射击点
      // this.$refs.targetBox.cleanPoint();
      // 清空列表中所有射击数据
      this.$refs.listBox.clearGroupListData();
      // 重置当前环数区域
      this.newest_score = 0;
      // 重置总成绩区域
      this.total_score = 0;
    },
    /**
     * 当接收到新比赛信息或比赛结束信号清空比赛数据和重置比赛状态
     */
    clearMatchData: function () {
      // 清空列表中所有射击数据
      this.$refs.listBox.clearGroupListData();
      // 重置靶图缩放大小
      this.rifle_target_scale_ratio = 45.5 / 15.5;
      this.pistol_target_scale_ratio = 155.5 / 59.5;
      // 当接受到新的比赛信息时，排名区域重置
      this.$refs.rankBox.resetAni();
      // 清空射击点坐标集合数组
      this.shoot_point_array = [];
      // 清空最新瞄准曲线坐标集合数组
      this.shoot_aim_curve_point_array = [];
      // 清空最新压力曲线压力值集合数组
      this.shoot_pressure_value_array = [];
      // 重置击发点压力值
      this.shoot_fire_pressure = 0;
      // 清空射击点
      // this.$refs.targetBox.cleanPoint();
      // 靶图恢复默认显示环值
      this.show_target_ring = 1;
      // 当接收到新的比赛信息时，清空排名数组
      this.shoot_exit_rank_array = [];
      // 重置当前发序
      this.current_order = 0;
      // 重置当前环数区域
      this.newest_score = 0;
      // 重置总成绩区域
      this.total_score = 0;
      // 重置目前赛事状态
      this.isNeedOddFinals = false;
      this.isOddShootingFinals = false;
      this.isFinishMatchFinals = false;
      // 重置决赛完赛遮罩
      this.isOverMask = false;
    },
    /**
     * 清空资格赛阶段上一组选手射击数据
     */
    clearLastGroupPlayerData: function () {
      // 清空列表中所有射击数据
      this.$refs.listBox.clearGroupListData();
      // 重置靶图缩放大小
      this.rifle_target_scale_ratio = 45.5 / 15.5;
      this.pistol_target_scale_ratio = 155.5 / 59.5;
      // 当接受到新的比赛信息时，排名区域重置
      this.$refs.rankBox.resetAni();
      // 清空射击点坐标集合数组
      this.shoot_point_array = [];
      // 清空最新瞄准曲线坐标集合数组
      this.shoot_aim_curve_point_array = [];
      // 清空最新压力曲线压力值集合数组
      this.shoot_pressure_value_array = [];
      // 重置击发点压力值
      this.shoot_fire_pressure = 0;
      // 清空射击点
      // this.$refs.DrawPointComponent.cleanPoint();
      // 靶图恢复默认显示环值
      this.show_target_ring = 1;
      // 当接收到新的比赛信息时，清空排名数组
      this.shoot_exit_rank_array = [];
      // 重置当前发序
      this.current_order = 0;
      // 重置当前环数区域
      this.newest_score = 0;
      // 重置总成绩区域
      this.total_score = 0;
    },
    /**
     * Ajax全局请求方法
     */
    requestServer: function (spliceUrl, paramData) {
      // 请求返回数据存放
      var ajaxData;
      $.ajax({
        async: false,
        url: this.$axios.baseUrl + spliceUrl,
        type: "get",
        dataType: "json",
        data: paramData,
        success: function (data) {
          ajaxData = data;
        },
      });
      return ajaxData.data;
    },
    /**
     * websocket信号处理
     */
    // signalHandle: function () {
    //   if (this.$store.state.hasOwnProperty("wsMsg")) {
    //     switch (this.$store.state.wsMsg.type) {
    //       // 初始化头显
    //       case "ON_OPEN":
    //         sessionStorage.setItem("avId", this.$store.state.wsMsg.data.id.id);
    //         break;
    //       // 绑定头显
    //       case "ON_BIND":
    //         this.qr_info(this.$store.state.wsMsg.data);
    //         break;
    //       // 实时数据
    //       case "REAL_TIME_DATA":
    //       case "C_REAL_TIME_DATA":
    //         this.timely_info(this.$store.state.wsMsg);
    //         break;
    //       // 射击数据
    //       case "SHOOTING_DATA":
    //         this.shoot_info(this.$store.state.wsMsg);
    //         break;
    //       // 排名数据
    //       case "RANK":
    //         console.log("执行");
    //         this.rank_info(this.$store.state.wsMsg);
    //         break;
    //       // 排名数据
    //       case "C_MATCH":
    //         this.clearTestingData();
    //         this.$nextTick(function () {
    //           this.$refs.rankBox.matchCircle();
    //         });
    //         break;
    //       case "SEAT":
    //         //设置队伍编号和对内选手序号
    //         this.number = this.$store.state.wsMsg.number + 1;
    //         this.team = this.$store.state.wsMsg.team + 1;
    //         break;
    //       case "C_ELIMINATE":
    //         console.log(this.$store.state.wsMsg.team);
    //         console.log(this.team);
    //         //打开遮罩
    //         if (
    //           this.$store.state.wsMsg.team === this.team - 1 ||
    //           this.ranking.indexOf(this.$store.state.wsMsg.team.toString()) ===
    //             1
    //         ) {
    //           this.isOverMask = true;
    //         }
    //         break;
    //       // 缩放数据
    //       case "P_TARGET_SIZE":
    //         //获取Canvas放大缩小到几环信号
    //         this.zoom_info(this.$store.state.wsMsg);
    //       default:
    //         break;
    //     }
    //   }
    // },
    signalHandle: function () {
      const wsMsg = JSON.parse(sessionStorage.getItem("wsMsg"));
      if (wsMsg.type) {
        switch (wsMsg.type) {
          // 初始化头显
          case "ON_OPEN":
            sessionStorage.setItem("avId", wsMsg.data.id.id);
            this.refresh();
            break;
          // 绑定头显
          case "ON_BIND":
            this.qr_info(wsMsg.data);
            break;
          // 实时数据
          case "REAL_TIME_DATA":
          case "C_REAL_TIME_DATA":
            this.timely_info(wsMsg);
            break;
          // 射击数据
          case "SHOOTING_DATA":
            this.shoot_info(wsMsg);
            break;
          // 排名数据
          case "RANK":
            this.rank_info(wsMsg);
            break;
          // 排名数据
          case "C_MATCH":
            this.clearTestingData();
            this.$nextTick(function () {
              this.$refs.rankBox.matchCircle();
            });
            break;
          case "SEAT":
            //设置队伍编号和对内选手序号
            this.number = this.$store.state.wsMsg.number + 1;
            this.team = this.$store.state.wsMsg.team + 1;
            break;
          case "C_ELIMINATE":
            //打开遮罩
            if (
              wsMsg.team === this.team - 1 ||
              this.ranking.indexOf(wsMsg.team.toString()) === 1
            ) {
              this.isOverMask = true;
            }
            break;
          // 广告
          case "AD":
            if (wsMsg.status === true) {
              this.isAdMask = true;
            } else this.isAdMask = false;
            break;
          // 缩放数据
          case "P_TARGET_SIZE":
            //获取Canvas放大缩小到几环信号
            this.zoom_info(wsMsg);
          default:
            break;
        }
      }
    },

    /**
     * 刷新触发
     */
    refresh: function () {
      if (window.name == "isReload") {
        // if (this.isOverMask == true) {
        //   this.$router.push({
        //     name: "QRScreen",
        //     query: {
        //       account: this.$route.query.account,
        //     },
        //   });
        // }
        console.log("页面被刷新");
        this.$axios
          .get("/athlete-view/athleteView/fix", {
            params: {
              avId: sessionStorage.getItem("avId"),
              account: this.$route.query.account,
            },
          })
          .then((res) => {
            if (res.data == "OK") {
              if (localStorage.getItem("refersh_Model") == "competition") {
                this.$axios.get("/athlete-view/athleteView/tryBind", {
                  params: {
                    competitionId: sessionStorage.getItem("competitionId"),
                    commonUserId: sessionStorage.getItem("commonUserId"),
                    flag:sessionStorage.getItem("gun_name")? localStorage.getItem("gun_name"):null
                  },
                });
              } else if (localStorage.getItem("refersh_Model") == "training") {
                this.$axios.get("/athlete-view/athleteView/tryBind", {
                  params: {
                    commonUserId: sessionStorage.getItem("commonUserId"),
                    flag:sessionStorage.getItem("gun_name")? localStorage.getItem("gun_name"):null
                  },
                });
              }
            } else {
              console.log("还没执行完fix");
            }
          });
      } else if (window.name == "matchOver") {
        this.$router.push({
          name: "QRScreen",
          query: {
            account: this.$route.query.account,
          },
        });
      }
    },
    /**
     * 处理扫码页面跳转参数
     */
    qr_info: function (qr_data) {
        console.log(qr_data)
        if(qr_data.flag){
            sessionStorage.setItem("gun_name", qr_data.flag);
        }
      var that = this;
      sessionStorage.setItem("AdCompetitionId", qr_data.competitionId);
      this.vue_playerInfo.athleteName = qr_data.realName; // 选手姓名赋值vue
      if (Object.keys(qr_data).includes("training")) {
        localStorage.setItem("refersh_Model", "training");
        sessionStorage.setItem("commonUserId", qr_data.commonUserId);
        this.shoot_model = "训练";
        // this.competitionId = qr_data.training.id; // 比赛ID赋值vue
        this.shoot_type = qr_data.training.event; // 比赛类型赋值vue
        this.avg_score = qr_data.training.averageScore; // 平均成绩赋值vue
        this.shoot_count = qr_data.training.quantity; // 射击数量赋值vue
        this.current_order = qr_data.training.quantity; // 当前发序赋值vue
        this.total_score = qr_data.training.totalScore; // 总成绩赋值vue
        this.standard_deviation = qr_data.training.standardDeviation; // 标准差赋值vue
        this.$nextTick(function () {
          that.data_reload(qr_data.shootingDataList);
        });
      } else if (Object.keys(qr_data).includes("competition")) {
        localStorage.setItem("refersh_Model", "competition");
        this.shoot_model = "比赛";
        sessionStorage.setItem("commonUserId", qr_data.commonUserId);
        sessionStorage.setItem("competitionId", qr_data.competitionId);
        if (qr_data.competition.team != null || "") {
          this.team = qr_data.competition.team + 1;
        } else {
          this.team = 0;
        }
        if (qr_data.competition.number != null || "") {
          this.number = qr_data.competition.number + 1;
        } else {
          console.log(this.number);
          this.number = 0;
        }
        this.competitionName = qr_data.competition.competitionName;
        this.competitionId = qr_data.competition.id; // 比赛ID赋值vue
        this.shoot_type = qr_data.competition.event; // 比赛类型赋值vue
        this.avg_score = qr_data.competition.averageScore; // 平均成绩赋值vue
        this.shoot_count = qr_data.competition.quantity; // 射击数量赋值vue
        this.current_order = qr_data.competition.quantity; // 当前发序赋值vue
        this.total_score = qr_data.competition.totalScore; // 总成绩赋值vue
        this.standard_deviation = qr_data.competition.standardDeviation; // 标准差赋值vue
        //  this.$refs.DrawTargetComponent.drawTarget();
        this.ranking = qr_data.ranking;
        this.$nextTick(function () {
          console.log(qr_data.shootingDataList);
          that.data_reload(qr_data.shootingDataList);
        });
      } else if (Object.keys(qr_data).includes("yy")) {
      }
    },
    /**
     * 处理数据恢复逻辑
     */
    data_reload: function (recoverShootData) {
      console.log("正在恢复数据...");
      var obj;
      this.isRecoveringData = true;
      // 系统崩溃重连后数据恢复需要将旧数据清空
      this.clearMatchData();

      // 排名区域展示“1”
      if (this.shoot_model === "训练") {
        this.$refs.rankBox.trainRank();
      }

      // 判断是否存在数据
      // 不存在数据代表射击未开始
      if (recoverShootData.length === 0 && this.shoot_model === "比赛") {
        // 排名区域展示“即将开始”
        this.$refs.rankBox.readyText();
      }
      if (recoverShootData.length > 0 && this.shoot_model === "比赛") {
        this.$nextTick(function () {
          this.$refs.rankBox.matchCircle();
          // if (this.ranking.length > 0) {
          // this.$refs.rankBox.rank_animation();
          // this.$refs.rankBox.setRank(
          //   this.ranking.indexOf(this.team.toString()) + 1
          // );
          // }
        });
      }
      if (recoverShootData.length != 0) {
        // 存在数据代表射击正在进行
        // 判断当前是否处于试射阶段
        // if (0) {
        //   this.isTesting = true; // 试射状态
        //   this.$refs.rankBox.testingText(); // 排名区域展示“正在试射”
        // } else {
        //   this.$refs.rankBox.startText(); // 排名区域展示“正式射击”
        // }
        // 恢复该靶道射击数据
        for (var i = 0; i < recoverShootData.length; i++) {
          obj = {
            orderNo:
              recoverShootData[i].order > 10000
                ? "*"
                : recoverShootData[i].order, // 发序
            shootPoint: {
              x: recoverShootData[i].aim.x,
              y: recoverShootData[i].aim.y,
            }, // 射击点坐标
            shootDirection: recoverShootData[i].direction, // 方位
            shootScore: recoverShootData[i].score, // 当前环数成绩
            shootTotal: recoverShootData[i].totalScore, // 总成绩
          };
          // 分组展示列表数据恢复
          this.$refs.listBox.chooseAddModel(obj);
          // 射击点集合恢复
          switch (this.shoot_model) {
            case "训练":
              if (recoverShootData[i].order % 10 === 1) {
                this.shoot_point_array = [];
              }
              this.shoot_point_array.push(recoverShootData[i].aim);
              break;
            case "比赛":
              if (recoverShootData[i].order % 10 === 1) {
                this.shoot_point_array = [];
              }
              this.shoot_point_array.push(recoverShootData[i].aim);
              break;
            case "比赛":
              if (recoverShootData[i].order <= 11) {
                if (recoverShootData[i].order % 5 === 1) {
                  this.shoot_point_array = [];
                }
              } else {
                if (recoverShootData[i].order % 2 === 1) {
                  this.shoot_point_array = [];
                }
              }
              this.shoot_point_array.push(recoverShootData[i].aim);
              break;
            default:
              break;
          }
          // 缓存问题不恢复曲线
          if (i === recoverShootData.length - 1) {
            this.current_order = recoverShootData[i].order;
            this.newest_score = recoverShootData[i].score;
            this.total_score = recoverShootData[i].totalScore;
          }
        }
      }
      console.log("数据恢复完成...");
    },
    /**
     * 处理实时数据
     */
    timely_info: function (timely_data) {
      var obj = {
        aimStage: timely_data.aimStage, // 曲线阶段
        x: timely_data.x, // 瞄准点横坐标
        y: timely_data.y, // 瞄准点纵坐标
        colorStatus: 0, // 曲线颜色状态：1：击发瞬间；2：击发前；3：击发后
      };
      // 压力不为0才可添加瞄点对象，如果为0代表已松开扳机不添加进数组
      if (timely_data.pressure !== 0) {
        switch (timely_data.aimStage) {
          // 击发前瞄点曲线数据
          case 0:
            // 如果瞄点曲线点集长度大于固定值则执行队列式（先进先出）新增瞄准点
            if (this.shoot_aim_curve_point_array.length >= 150) {
              this.shoot_aim_curve_point_array.shift();
            }
            obj.colorStatus = 1;
            this.shoot_aim_curve_point_array.push(obj);
            var len = this.shoot_aim_curve_point_array.length;
            if (len > 10) {
              this.shoot_aim_curve_point_array[len - 1 - 10].colorStatus = 2;
            }
            break;
          // 击发后瞄点曲线数据
          case 1:
            // 领导暂时取消击发后曲线
            // obj.colorStatus = 3;
            // this.shoot_aim_curve_point_array.push(obj);
            break;
          // 上膛信号
          case 201:
            // 当接收到上膛信号表示数据恢复已完成（如果在数据恢复方法内执行成绩列表会出问题，这里更新状态最妥）
            this.isRecoveringData = false;
            // 当接收到上膛信号修改状态
            this.isShooting = true;
            // 当接收到上膛信号清空瞄点数据
            this.shoot_aim_curve_point_array = [];
            break;
          // 松开一道火信号
          case 202:
            // 当接收到松开扳机信息清空旧瞄点数据
            this.shoot_aim_curve_point_array = [];
            break;
          // 击发数据，已在处理射击数据方法shoot_info中根据type属性处理
          case -1:
            break;
          default:
            break;
        }
      }
    },
    /**
     * 处理实时排名数据
     */
    rank_info: function (rank_data) {
      this.ranking = rank_data.ranking;
      if (this.ranking.length <= 0) {
        return;
      }
      // 如果当前靶道是需要单发决赛或已完成比赛则不会处理排名信息
      if (this.isNeedOddFinals == true || this.isFinishMatchFinals == true) {
        return;
      }
      // 当接收到射击数据开启排名显示
      this.$nextTick(function () {
        this.$refs.rankBox.matchCircle();
        if (this.ranking.indexOf((this.team - 1).toString()) != -1) {
          this.$refs.rankBox.rank_animation();
          this.$refs.rankBox.setRank(
            this.ranking.indexOf((this.team - 1).toString()) + 1
          );
        }
      });
    },
    /**
     * 处理每一枪射击数据
     */
    shoot_info: function (shoot_data) {
      // 当接收到射击数据证明不正在最新射击
      this.isShooting = false;
      // 更新当前成绩和总成绩的展示
      this.newest_score = shoot_data.score;
      if (
        !(
          this.isNeedOddFinals ||
          this.isOddShootingFinals ||
          this.isFinishMatchFinals
        )
      ) {
        // 当前发序赋值vue
        this.current_order = shoot_data.aim.order;
        // 如果不是进行单发射击或者选手被淘汰则正常更新总成绩
        this.total_score = shoot_data.totalScore;
      }
      var obj1 = {
        aimStage: shoot_data.aim.aimStage, // 曲线阶段
        x: shoot_data.aim.x, // 瞄准点横坐标
        y: shoot_data.aim.y, // 瞄准点纵坐标
        colorStatus: 0, // 曲线颜色状态
      };
      // 将击发点补充到瞄点曲线上
      this.shoot_aim_curve_point_array.push(obj1);
      var obj2 = {
        orderNo: this.isOddShootingFinals == false ? shoot_data.aim.order : "*",
        shootPoint: { x: shoot_data.aim.x, y: shoot_data.aim.y }, // 射击点坐标
        shootDirection: shoot_data.direction, // 方位
        shootScore: shoot_data.score, // 当前环数成绩
        shootTotal: shoot_data.totalScore, // 总成绩
      };
      // 分组展示列表数据恢复
      this.$refs.listBox.chooseAddModel(obj2);
      // 射击点集合恢复
      switch (this.shoot_model) {
        case "训练":
          if (shoot_data.aim.order % 10 == 1) {
            this.shoot_point_array = [];
          }
          this.shoot_point_array.push(shoot_data.aim);
          break;
        case "比赛":
          if (shoot_data.aim.order % 10 == 1) {
            this.shoot_point_array = [];
          }
          this.shoot_point_array.push(shoot_data.aim);
          break;
        case "决赛":
          if (!this.isOddShootingFinals) {
            // 如果不是正在进行单发射击
            if (shoot_data.aim.order <= 11) {
              if (shoot_data.aim.order % 5 == 1) {
                this.shoot_point_array = [];
              }
            } else {
              if (shoot_data.aim.order % 2 == 1) {
                this.shoot_point_array = [];
              }
            }
            this.shoot_point_array.push(shoot_data.aim);
          } else {
            // 正在进行单发射击
            if (this.isNeedOddFinals) {
              // 如果该靶道是准备进行单发射击的靶道
              this.shoot_point_array = [];
              this.shoot_point_array.push(shoot_data.aim);
            } else {
              // 如果该靶道不是准备进行单发射击的靶道，则数据不变
            }
          }
          break;
        default:
          break;
      }
    },
    /**
     * 处理Canvas缩放JSON信息
     */
    zoom_info: function (zoom_data) {
      console.log(zoom_data);
      // 最好打印实际数据查看信息
      // var info = JSON.parse(zoom_data);
      var zoom = zoom_data.zoom;
      // 限制靶图最大放大显示到7环
      if (zoom !== 0 && zoom <= 7) {
        this.show_target_ring = zoom;
      }
    },
  },
};
</script>

<style lang="less">
@import url("../assets/css/screen-show.css");
</style>
