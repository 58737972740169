<template>
  <div class="mask">
    <div class="finals-mask-block">
      <div class="finish-mask-left">
        <div class="finish-text-ele">
          <div class="finish-remark-ele">国家：</div>
          <div v-if="player_info.athleteName" class="finish-country">
            <div class="finish-flag-img">
              <img :src="require('../../assets/' + nationalFlagSrc)" />
            </div>
            <div class="finish-flag-name">CHINA</div>
          </div>
        </div>
        <div class="finish-text-ele">
          <div class="finish-remark-ele">选手：</div>
          <div class="finish-detail-ele">{{ player_info.athleteName }}</div>
        </div>
        <div class="finish-text-ele">
          <div class="finish-remark-ele">队伍：</div>
          <div class="finish-detail-ele">{{ team }}</div>
        </div>
        <div class="finish-text-ele">
          <div class="finish-remark-ele">总成绩：</div>
          <div class="finish-detail-ele">{{ total_score.toFixed(1) }}</div>
        </div>
      </div>
      <div class="finish-mask-right">
        <div
          v-if="rank_num < 10"
          class="finish-rank-ele"
          :style="{ color: mask_rank_color, transform: one_digit_font_size }"
        >
          {{ rank_num }}
        </div>
        <div
          v-else
          class="finish-rank-ele"
          :style="{ color: mask_rank_color, transform: two_digit_font_size }"
        >
          {{ rank_num }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FinishFinalsMaskBox",
  mask_rank_color: "",
  props: {
    player_info: {
      default: {},
      type: Object,
    },
    rank_num: {
      default: 0,
      type: Number,
    },
    team: {
      default: 0,
      type: Number,
    },
    total_score: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      //排名数字大小
      one_digit_font_size: "scale(1, 1)",
      two_digit_font_size: "scale(0.5, 0.5)",
      nationalFlagSrc: "images/中国国旗.jpg",
      mask_rank_color: "",
    };
  },
  mounted() {
    this.setRankColor();
    window.name = "matchOver";
    sessionStorage.setItem("windowName","matchOver")
  },
  methods: {
    setRankColor() {
      switch (this.rank_num) {
        case 0:
          this.mask_rank_color = "#FFFFFF";
          break;
        case 1:
          this.mask_rank_color = "#FF0000";
          break;
        case 2:
          this.mask_rank_color = "#FF7D00";
          break;
        case 3:
          this.mask_rank_color = "#FFFF00";
          break;
        case 4:
          this.mask_rank_color = "#00FF00";
          break;
        case 5:
          this.mask_rank_color = "#00FFFF";
          break;
        case 6:
          this.mask_rank_color = "#0000FF";
          break;
        case 7:
          this.mask_rank_color = "#FF00FF";
          break;
        case 8:
          this.mask_rank_color = "#FFC0CB";
          break;
        case 9:
          this.mask_rank_color = "#CCCCCC";
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style></style>
