<template>
  <div class="shoots-list-ele-bottom-text">
    <div v-if="arr.orderNo == '*'" class="first-text">{{ arr.orderNo }}</div>
    <div v-else class="first-text">
      {{ arr.orderNo < 10 ? "0" + arr.orderNo : arr.orderNo }}
    </div>
    <div v-if="arr.shootScore < 1" class="second-arrow">×</div>
    <div v-else-if="arr.shootScore == 10.9" class="second-arrow">·</div>
    <div
      v-else
      class="second-arrow"
      :style="{ transform: 'rotate(' + arr.shootDirection + 'deg)' }"
    >
      ↑
    </div>
    <div class="third-text">{{ arr.shootScore.toFixed(1) }}</div>
  </div>
</template>

<script>
export default {
  name: "AllShootsChildBox",
  props: {
    arr: {
      default: {},
      type: Object,
    },
  },
};
</script>

<style>
</style>