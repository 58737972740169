import Vue from 'vue'
import VueRouter from 'vue-router'
import QRScreen from '@/views/QRScreen.vue'
import AthleteScreen from '@/views/AthleteScreen.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'QRScreen',
        component: QRScreen,
        meta: {
            title: '激光枪投屏显示'
        }
    },
    {
        path: '/athlete',
        name: 'AthleteScreen',
        component: AthleteScreen,
        meta: {
            title: '激光枪投屏显示'
        }
    }
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router