<template>
  <div class="all">
    <div class="background">
      <div class="title">
        <p>头显</p>
      </div>
      <div class="qr-block">
        <div class="top-section">
          <div class="qr-code" ref="qrCodeDiv"></div>
        </div>
        <div class="bottom-section">
          <div v-if="show_qr_value" class="qr-text">{{ qr_value }}</div>
        </div>
      </div>
      <div class="logo-block">
        <div class="left-section">
          <img class="company-logo" :src="logo" />
        </div>
        <div class="right-section">
          <div class="company-name">珠海强源体育用品有限公司</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import logo from "../assets/images/company.png";

export default {
  name: "QRScreen",
  data() {
    return {
      /**
       * 二维码的宽高（自适应网页可视区宽高）
       */
      canvas_side: document.body.clientHeight * 0.8,
      /**
       * 二维码的值
       */
      qr_value: "",
      is_ws_connected: false, // 网络服务是否已连接
      show_qr_value: false, // 是否展示二维码的值
      timer: false, // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      account: "",
      logo,
    };
  },
  computed: {
    getWsMsg() {
      return this.$store.state.wsMsg;
    },
  },
  watch: {
    /**
     * 监听websocket获取数据的全局变量
     */
    getWsMsg: function (result) {
        console.log(result)
      switch (result.type) {
        case "ON_OPEN":
          this.qr_value = JSON.stringify(result.data.id);
          let avId = result.data.id.id;
          var url = window.location.href;
          let index = url.lastIndexOf("=");
          if (index > 0) {
            url = url.substring(index + 1, url.length);
            this.account = url;
          }
          sessionStorage.setItem("avId", avId);
          sessionStorage.setItem("account", url);

          // 如果为空则输出没有参数
          if (window.location.href.indexOf("account") != -1 && url !== "") {
            this.$axios.get("/athlete-view/athleteView/fix", {
              params: {
                avId: avId,
                account: url,
              },
            });
          } else {
            console.log("没有参数");
          }
          break;
        case "ON_BIND":
          this.dealScanCodeBind(result.data);
          break;
        default:
          break;
      }
    },
    /**
     * 监听二维码参数值变化局部刷新
     */
    qr_value: function () {
      this.bindQRCode();
    },
  },
  mounted: function () {
    if (sessionStorage.getItem("windowName") == "matchOver") {
      sessionStorage.setItem("windowName", "matchOvered");
      location.reload();
    }
    window.name = "";
    this.bindQRCode();
    // 在页面mounted时，挂载window.onresize方法，实现页面自适应
    const that = this;
    window.addEventListener("resize", () => {
      return (
        (() => {
          // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
          if (!that.timer) {
            that.timer = true;
            setTimeout(function () {
              // 重新赋值最新窗口数据
              that.canvas_side = document.body.clientHeight * 0.8;
              that.bindQRCode();
              that.timer = false;
            }, 50);
          }
        })(),
        false
      );
    });
  },

  methods: {
    /**
     * 设置二维码参数
     */
    QRCode: function () {
      if (this.$refs.qrCodeDiv !== undefined) {
        this.$refs.qrCodeDiv.innerHTML = "";
        let qr = new QRCode(this.$refs.qrCodeDiv, {
          text: this.qr_value,
          width: this.canvas_side,
          height: this.canvas_side,
          colorDark: "#000000", // 二维码颜色
          colorLight: "#ffffff", // 二维码背景色
          correctLevel: QRCode.CorrectLevel.L, // 容错率，L/M/H
        });
        qr._el.title = "";
      }
    },
    /**
     * 创建二维码
     */
    bindQRCode: function () {
      this.$nextTick(function () {
        this.QRCode();
      });
    },
    /**
     * 处理扫码绑定事件
     */
    dealScanCodeBind: function () {
      this.$router.push({
        name: "AthleteScreen",
        query: {
          account: this.account,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import url("../assets/css/screen-qr.css");
.title {
  text-align: right;
  font-size: 1.5vw;
}
</style>
