<template>
  <div class="athlete-ele-name">
    <div v-if="player_info.athleteName" class="country">
      <div class="flag-img">
        <img :src="require('@/' + nationalFlagSrc)" alt="" />
      </div>
      <div class="flag-name">CHINA</div>
    </div>
    <div class="athlete-ele-name-text">{{ player_info.athleteName }}</div>
  </div>
</template>

<script>
export default {
  name: "PlayerNameBox",
  props: {
    player_info: {
      default: {},
      type: Object,
    },
  },
  data() {
    return {
      nationalFlagSrc: "assets/images/中国国旗.jpg",
    };
  },
};
</script>

<style>
</style>