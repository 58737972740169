<template>
  <canvas id="aim-curve" class="curve-canvas"></canvas>
</template>

<script>
export default {
  name: "DrawCurveComponent",
  props: {
    shoot_type: {
      default: "",
      type: String,
    },
    show_target_ring: {
      default: 0,
      type: Number,
    },
    shoot_aim_curve_point_array: {
      default: [],
      type: Array,
    },
    isShooting: {
      default: false,
      type: Boolean,
    },
  },
  mounted() {
    this.paint_aim_curve();
    // 在页面mounted时，挂载window.onresize方法，实现页面自适应
    const that = this;
    // window.addEventListener("resize", () => {
    //   return (
    //     (() => {
    //       // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
    //       if (!that.timer) {
    //         that.timer = true;
    //         setTimeout(function () {
    //           // 重新赋值最新窗口数据
    //           that.canvas_width = (document.body.clientWidth - 8) * 0.8 * 0.725;
    //           that.canvas_height = (document.body.clientHeight - 6) * 0.88;
    //           that.paint_aim_curve();
    //           that.timer = false;
    //         }, 200);
    //       }
    //     })(),
    //     false
    //   );
    // });
     // 打印功能需要重新修改
    const observer = new ResizeObserver ((entries)=>{

        if (!that.timer) {
            that.timer = true;
            setTimeout(function () {
              // 重新赋值最新窗口数据
              that.canvas_width = (document.body.clientWidth - 8) * 0.8 * 0.725;
              that.canvas_height = (document.body.clientHeight - 6) * 0.88;
              that.paint_aim_curve();
              that.timer = false;
            }, 200);
          }
    });
    observer.observe(document.body);
  },
  watch: {
    // 监听显示环值重绘曲线
    show_target_ring: function () {
      this.draw_curve();
    },
    // 监听射击数据发生变化执行重绘
    shoot_aim_curve_point_array: function () {
      this.draw_curve();
    },
    // 监听上膛信号执行重绘
    isShooting: function () {
      this.draw_curve();
    },
  },
  data() {
    return {
      canvas_width: (document.body.clientWidth - 8) * 0.8 * 0.725,
      canvas_height: (document.body.clientHeight - 6) * 0.88,
      rifleTenRingDiameter: 0.5, //步枪十环直径
      rifleRingDiameterDiff: 5, //步枪环直径差
      pistolTenRingDiameter: 11.5, //手枪十环直径
      pistolRingDiameterDiff: 16, //手枪环直径差
      rifle_target_scale_ratio: 45.5 / 15.5, //步枪初始缩放比例
      pistol_target_scale_ratio: 155.5 / 59.5, //手枪初始缩放比例
      curve_before_color: "#00FFFF", // 击发前曲线颜色
      curve_moment_color: "#FF00FF", // 击发前0.4s瞬间曲线颜色
      curve_after_color: "#CCCCCC", // 击发后曲线颜色
      curve_endpoint_color: "#EEEE00", // 曲线端点颜色
      timer: false, // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
    };
  },
  methods: {
    draw_curve: function () {
      var changeMultiple;
      if (this.shoot_type == "RIFLE") {
        changeMultiple =
          (this.rifleTenRingDiameter +
            (10 - this.show_target_ring) * this.rifleRingDiameterDiff) /
          45.5;
        //将缩放比例赋值Vue
        this.rifle_target_scale_ratio = 1 / changeMultiple;
      } else if (this.shoot_type == "PISTOL") {
        changeMultiple =
          (this.pistolTenRingDiameter +
            (10 - this.show_target_ring) * this.pistolRingDiameterDiff) /
          155.5;
        //将缩放比例赋值Vue
        this.pistol_target_scale_ratio = 1 / changeMultiple;
      }
      this.paint_aim_curve();
    },
    /**
     * 绘制瞄点曲线
     */
    paint_aim_curve: function () {
      // 瞄准曲线数组长度
      var len = this.shoot_aim_curve_point_array.length;
      // 如果数据长度小于3清空画布并退出方法
      if (len < 3) {
        this.cleanCurve();
        return;
      }
      // 瞄准曲线末端点对象
      var endPoint = this.shoot_aim_curve_point_array[len - 1];
      // 如果处于击发前最新的瞄点飞出靶图清空画布并退出方法
      if (Math.abs(endPoint.x) > 1 || Math.abs(endPoint.y) > 1) {
        this.cleanCurve();
        return;
      }
      // 根据数组对象属性值分类
      // var class_arr = this.classifyObjectArray(
      //   this.shoot_aim_curve_point_array
      // );
      var scale; //获取缩放比例
      if (this.shoot_type == "RIFLE") {
        scale = this.rifle_target_scale_ratio;
      } else if (this.shoot_type == "PISTOL") {
        scale = this.pistol_target_scale_ratio;
      }
      var targetBoxWidth =
        document.getElementsByClassName("target-ele")[0].clientWidth; //获取外层div宽度，决定靶心偏移量
      var canvasCurve = document.getElementById("aim-curve");
      canvasCurve.setAttribute("width", this.canvas_width * scale);
      canvasCurve.setAttribute("height", this.canvas_height * scale);
      var ctx = canvasCurve.getContext("2d");
      // 加载前清空画布
      ctx.clearRect(0, 0, canvasCurve.clientWidth, canvasCurve.clientHeight);
      var w = canvasCurve.clientWidth / 2; //根据(0, 0)点横坐标需要移动的距离
      var h = canvasCurve.clientHeight / 2; //根据(0, 0)点纵坐标需要移动的距离
      ctx.translate(-(w - targetBoxWidth / 2), 0); //缩放横移
      // 定义端点半径、坐标参数
      var radius;
      var ring_height = canvasCurve.clientHeight * 0.96; //核心区域高
      var ring_radius = ring_height / 2; //核心区域最外圈半径
      if (this.shoot_type == "RIFLE") {
        radius = 7 * scale;
      } else if (this.shoot_type == "PISTOL") {
        radius = 2 * scale;
      }
      var ele1, ele2, ele3;
      var sx, sy, mx, my, ex, ey;
      var midblue1x, midblue1y, midblue2x, midblue2y;
      var controlchangex, controlchangey;
      var controlx1, controly1, controlx2, controly2;
      for (var i = 0; i < len - 2; i++) {
        ele1 = this.shoot_aim_curve_point_array[i];
        ele2 = this.shoot_aim_curve_point_array[i + 1];
        ele3 = this.shoot_aim_curve_point_array[i + 2];
        // 三个数据点同时为击发前或击发后绘制三阶贝塞尔曲线
        sx = ring_radius * ele1.x + w;
        sy = ring_radius * ele1.y + h;
        mx = ring_radius * ele2.x + w;
        my = ring_radius * ele2.y + h;
        ex = ring_radius * ele3.x + w;
        ey = ring_radius * ele3.y + h;
        midblue1x = (sx + mx) / 2;
        midblue1y = (sy + my) / 2;
        midblue2x = (ex + mx) / 2;
        midblue2y = (ey + my) / 2;
        controlchangex = (midblue2x + midblue1x) / 2;
        controlchangey = (midblue2y + midblue1y) / 2;
        controlx1 = mx - controlchangex + midblue1x;
        controly1 = my - controlchangey + midblue1y;
        controlx2 = mx - controlchangex + midblue2x;
        controly2 = my - controlchangey + midblue2y;
        switch (ele1.colorStatus) {
          case 1:
            // 击发前10个点曲线指定颜色
            ctx.strokeStyle = this.curve_moment_color;
            break;
          case 2:
            // 击发前曲线指定颜色
            ctx.strokeStyle = this.curve_before_color;
            break;
          case 3:
            // 击发后曲线指定颜色
            ctx.strokeStyle = this.curve_after_color;
            break;
          default:
            break;
        }
        // 曲线指定宽度
        
        ctx.lineWidth = 1.5;
        ctx.beginPath();
        ctx.moveTo(sx, sy);
        ctx.bezierCurveTo(controlx1, controly1, controlx2, controly2, ex, ey);
        ctx.stroke();
        // 定义端点对象
        var coordinate;
        // 击发前瞄点数据的最后一点绘制线端点
        if (i === len - 3 && endPoint.aimStage === 0) {
          coordinate = {
            x: ring_radius * endPoint.x + w,
            y: ring_radius * endPoint.y + h,
          };
          ctx.fillStyle = this.curve_endpoint_color;
          ctx.beginPath();
          ctx.arc(coordinate.x, coordinate.y, radius, 0, Math.PI * 2);
          ctx.fill();
        }
       
        // 击发点绘制端点
        // 获取击发点下标且判断是否存在击发点
        
        var index = this.getObjectIndex(this.shoot_aim_curve_point_array);
        if (index !== -1) {
          // 存在击发点
          // 击发点对象
          var firePoint = this.shoot_aim_curve_point_array[index];
          coordinate = {
            x: 0,
            y: ring_radius * firePoint.y + h,
          };
          ctx.fillStyle = this.curve_endpoint_color;
          ctx.beginPath();
          ctx.arc(coordinate.x, coordinate.y, radius, 0, Math.PI * 2);
          ctx.fill();
        }
      }
    },
    /**
     * 将瞄准点数组根据aimStage进行分类的方法
     */
    classifyObjectArray: function (arr) {
      var dataArr = [];
      arr.map((mapItem) => {
        if (dataArr.length === 0) {
          dataArr.push({ aimStage: mapItem.aimStage, list: [mapItem] });
        } else {
          let res = dataArr.some((item) => {
            //判断相同值，有就添加到当前项
            if (item.aimStage === mapItem.aimStage) {
              item.list.push(mapItem);
              return true;
            }
          });
          if (!res) {
            //如果没找相同值的时候添加一个新对象
            dataArr.push({ aimStage: mapItem.aimStage, list: [mapItem] });
          }
        }
      });
      return dataArr;
    },
    /**
     * 根据aimStage=-1获取击发点下标方法
     */
    getObjectIndex: function (arr) {
      var index = arr.findIndex(function (item) {
        return item.aimStage === -1;
      });
      return index;
    },
    /**
     * 清空瞄点曲线
     */
    cleanCurve: function () {
      var canvasReset = document.getElementById("aim-curve");
      var ctx = canvasReset.getContext("2d");
      ctx.clearRect(0, 0, canvasReset.clientWidth, canvasReset.clientHeight);
    },
  },
};
</script>

<style>
</style>